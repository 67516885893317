import { apiSlice } from "./apiSlice";
import { DASHBOARD_URL } from "../constants";

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTop: builder.query({
      query: () => ({
        url: `${DASHBOARD_URL}/top/month`,
      }),
    }),
    getTopSelling: builder.query({
      query: () => ({
        url: `${DASHBOARD_URL}/products/month`,
      }),
    }),
    getRecentSales: builder.query({
      query: () => ({
        url: `${DASHBOARD_URL}/sales/month`,
      }),
    }),
    getReports: builder.query({
      query: () => ({
        url: `${DASHBOARD_URL}/reports/month`,
      }),
    }),
  }),
});

export const { useGetRecentSalesQuery, useGetReportsQuery, useGetTopQuery, useGetTopSellingQuery } =
  dashboardApiSlice;
