import { FaTrashAlt } from "react-icons/fa";

const Trash = (props) => {
  return (
    <div
      className="fixed bottom-0 right-0 mb-8 mr-8 cursor-pointer rounded-full bg-blue p-4 text-white lg:text-3xl 2xl:text-5xl"
      onClick={props.onClick}
    >
      <FaTrashAlt />
    </div>
  );
};

export default Trash;
