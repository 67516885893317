import { useState, useEffect } from "react";
import { FaCircle } from "react-icons/fa";
import { BsCircle } from "react-icons/bs";

import "./Content.css";
import "../../Shared/css/MainContentContainer.css";

import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";

import Loader from "../../Loader";
import FullMessage from "./FullMessage";

const Content = (props) => {
  const { notifications } = useSelector((state) => state.auth);

  const [showPopup, setShowPopup] = useState([false, null]);
  const [activeTab, setActiveTab] = useState("all");
  const [activeNotification, setActiveNotification] = useState(null);
  
  // FUNCTION TO SEARCH
  let result = 0;
  if (props.search.length > 0) {
    let searchWord = props.search.toLowerCase();

    result = notifications.filter((notification) =>
      notification.title.toLowerCase().match(searchWord) ||
      notification.subtitle.toLowerCase().match(searchWord) ||
      notification.description.toLowerCase().match(searchWord)
    );
  }

  return (
    <>
      <main className="main-content-container notification-main-section">
        <section className="top mb-8 flex gap-x-4">
          <p
            className={`${
              activeTab === "all" ? "active" : ""
            } flex cursor-pointer items-center gap-x-3 px-4 py-2 text-2xl font-semibold hover:rounded-lg hover:bg-accentpurple active:border-2 active:border-blue`}
            onClick={() => setActiveTab("all")}
          >
            All{" "}
            <span className="flex h-7 w-7 items-center justify-center rounded-full bg-blue text-accentpurple">
              {notifications.length}
            </span>
          </p>
          <p
            className={`${
              activeTab === "unread" ? "active" : ""
            } flex cursor-pointer items-center gap-x-3 px-4 py-2 text-2xl font-semibold hover:rounded-lg hover:bg-accentpurple active:border-2 active:border-blue`}
            onClick={() => setActiveTab("unread")}
          >
            Unread{" "}
            <span className="flex h-7 w-7 items-center justify-center rounded-full bg-blue text-accentpurple">
              {notifications.filter((notification) => !notification.statusmanager).length}
            </span>
          </p>
        </section>

        <section className="bottom overflow-scroll">
          {props.loading ?
            <>
              <div
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                >
                <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                    <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                    {/* Adjust border radius */}
                </ContentLoader>
              </div>
              <div
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                >
                <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                    <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                    {/* Adjust border radius */}
                </ContentLoader>
              </div>
              <div
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                >
                <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                    <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                    {/* Adjust border radius */}
                </ContentLoader>
              </div>
              <div
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                >
                <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                    <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                    {/* Adjust border radius */}
                </ContentLoader>
              </div>
              <div
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                >
                <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                    <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                    {/* Adjust border radius */}
                </ContentLoader>
              </div>
              <div
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                >
                <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                    <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                    {/* Adjust border radius */}
                </ContentLoader>
              </div>
              <div
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                >
                <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                    <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                    {/* Adjust border radius */}
                </ContentLoader>
              </div>
              <div
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                >
                <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                    <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                    {/* Adjust border radius */}
                </ContentLoader>
              </div>
              <div
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                >
                <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                    <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                    {/* Adjust border radius */}
                </ContentLoader>
              </div>
              <div
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                >
                <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                    <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                    {/* Adjust border radius */}
                </ContentLoader>
              </div>
            </>
            : activeTab === "all"
            ? (result.length > 0 ? (
            result.map((notification, index) => (
              <div
                  id={index}
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                  onClick={() => {setShowPopup([true, index]); setActiveNotification(notification)}}
                >
                  <h2 className="font-semibold capitalize">{notification.title}</h2>
                  <div className="flex items-center gap-x-5">
                    <p>{notification.formatted_date}</p>

                    {!notification.statusmanager ? (
                      <FaCircle className="text-lighterpurple" />
                    ) : (
                      <BsCircle className="text-accentpurple" />
                    )}
                  </div>
                </div>
            ))
          ) : notifications.map((notification, index) => (
                <div
                  id={index}
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                  onClick={() => {setShowPopup([true, index]); setActiveNotification(notification)}}
                >
                  <h2 className="font-semibold capitalize">{notification.title}</h2>
                  <div className="flex items-center gap-x-5">
                    <p>{notification.formatted_date}</p>

                    {!notification.statusmanager ? (
                      <FaCircle className="text-lighterpurple" />
                    ) : (
                      <BsCircle className="text-accentpurple" />
                    )}
                  </div>
                </div>
              ))
              )
            : (result.length > 0 ? (
            result.map((notification, index) => (
              <div
                  id={index}
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                  onClick={() => {setShowPopup([true, index]); setActiveNotification(notification)}}
                >
                  <h2 className="font-semibold capitalize">{notification.title}</h2>
                  <div className="flex items-center gap-x-5">
                    <p>{notification.formatted_date}</p>

                    {!notification.statusmanager ? (
                      <FaCircle className="text-lighterpurple" />
                    ) : (
                      <BsCircle className="text-accentpurple" />
                    )}
                  </div>
                </div>
            ))
          ) : notifications.filter(notification => !notification.statusmanager).map((notification, index) => (
                <div
                  id={index}
                  className="notification mb-4 flex items-center justify-between rounded-2xl bg-accentpurple px-8 py-4 text-blue hover:border-2 hover:border-lighterpurple"
                  onClick={() => {setShowPopup([true, index]); setActiveNotification(notification)}}
                >
                  <h2 className="font-semibold capitalize">{notification.title}</h2>
                  <div className="flex items-center gap-x-5">
                    <p>{notification.formatted_date}</p>

                    {!notification.statusmanager ? (
                      <FaCircle className="text-lighterpurple" />
                    ) : (
                      <BsCircle className="text-accentpurple" />
                    )}
                  </div>
                </div>
              ))
            )}
        </section>
      </main>

      {showPopup[0] === true ? (
        <FullMessage
          setShowPopup={setShowPopup}
          activeNotification={activeNotification}
        />
      ) : null}
    </>
  );
};

export default Content;
