import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useLogoutMutation } from "../../slices/userApiSlice";
import { logout } from "../../slices/authSlice";

// import "./SideBar.css";
import "../Shared/css/SideBar.css";
import Logo from "../../images/Logo2.svg";
import UserManual from "../StockPilot_User_Manual.pdf";

import { AiFillHome, AiFillMessage } from "react-icons/ai";
import { BiSolidStore, BiSolidHelpCircle } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { FaUsers } from "react-icons/fa";

import Loader from "../Loader";

const links = [
  {
    name: "Home",
    icon: <AiFillHome />,
    path: "/admin/home",
  },
  {
    name: "Employee",
    icon: <FaUsers />,
    path: "/admin/employees",
  },
  {
    name: "Store",
    icon: <BiSolidStore />,
    path: "/admin/stores",
  },
  {
    name: "Notifications",
    icon: <AiFillMessage />,
    path: "/admin/notifications",
  },
];

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userProfile } = useSelector((state) => state.auth);

  const [logOut, { isLoading: logoutLoader }] = useLogoutMutation();
  const handleLogout = async () => {
    dispatch(logout());
    navigate("/login");
    const res = await logOut({ id: userProfile.user_id }).unwrap();
    toast.success(res.message);
  };

  return (
    <div>
      {logoutLoader && <Loader />}
      <div className="admin-sidebar mx-2 flex h-full flex-col justify-center rounded-3xl bg-blue px-8 text-accentpurple 2xl:w-80 2xl:gap-y-28 lg:w-72 lg:gap-y-16 sm:m-0 sm:w-max sm:rounded-lg sm:p-2">
        <div className="logo flex flex-col items-center justify-center">
          <img
            src={Logo}
            alt="company logo"
            className="2xl:w-56 lg:w-48 sm:hidden"
          />
          <h3 className="mt-2 text-xl sm:hidden">
            {userProfile.business_name}
          </h3>
          <hr className=" border-1 mx-auto w-full border-accentpurple 2xl:mt-10 lg:mt-6" />
        </div>

        <div className="links flex flex-col justify-center">
          {links.map((link) => (
            <NavLink
              to={link.path}
              className={
                "flex items-center gap-5 active:bg-red-700 2xl:mb-8 2xl:pl-6 2xl:text-xl lg:mb-6 lg:pl-5 lg:text-lg sm:w-full sm:items-center sm:justify-center sm:px-1"
              }
            >
              {link.icon} <span className="sm:hidden">{link.name}</span>
            </NavLink>
          ))}

          <hr className="border-1 mx-auto w-full border-accentpurple lg:mt-0" />
        </div>

        <div className="bottom">
          <Link
            className="log-out flex items-center justify-center gap-2 hover:underline hover:underline-offset-4 2xl:mb-8  2xl:text-xl lg:mb-3 lg:text-lg"
            to="#"
            onClick={handleLogout}
          >
            <FiLogOut />
            <span className="sm:hidden">Log Out</span>
          </Link>

          <div className="help flex justify-center font-semibold hover:underline hover:underline-offset-4">
            <a
              href={UserManual}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-xl"
            >
              <BiSolidHelpCircle />
              <span className="sm:hidden">Need Help</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
