import SideBar from "./SideBar";
import Header from "./Employee/Header";
import Content from "./Employee/Content";
import Meta from "../Meta";
import Loader from "../Loader";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setEmployees } from "../../slices/authSlice";
import { toast } from "react-toastify";
import { useGetEmployeesQuery } from "../../slices/employeeApiSlice";

import "../Shared/css/Wrapper.css";
import "../Shared/css/ContentContainer.css";

const ManagerEmployeeRoute = (props) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const {
    data: employees,
    isLoading: employeeLoader,
    error: employeeError,
    isFetching: employeeFetcher,
  } = useGetEmployeesQuery(null, {
    pollingInterval: 5 * 1000, // Refetch every 5 seconds
  });

  useEffect(() => {
    if (employeeError) {
      toast.error(employeeError?.data?.message || "Network Error", { toastId: 'E1' });
    }

    if (!employeeError && !employeeLoader) {
      dispatch(setEmployees(employees.employees));
    }
  }, [dispatch, employees, employeeError, employeeLoader]);

  return (
    <div className="wrapper">
      {employeeLoader && <Loader />}
      <Meta title={"StockPilot - Manager Employees"} />
      <SideBar />

      <div className="manager-content-container">
        <Header setSearch={setSearch} />
        {!employeeError && (
          <Content
            search={search}
            loading={employeeLoader}
          />
        )}
      </div>
    </div>
  );
};

export default ManagerEmployeeRoute;
