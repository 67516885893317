import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const { userProfile } = useSelector((state) => state.auth);

  return (
    <>
      {!userProfile && <Navigate to="/login" replace />}
      {userProfile && userProfile.role === "admin" && (
        <Navigate to="/admin/home" replace />
      )}
      {userProfile && userProfile.role === "manager" && (
        <Navigate to="/manager/home" replace />
      )}
      {userProfile && userProfile.role === "cashier" && (
        <Navigate to="/cashier/home" replace />
      )}
    </>
  );
};

export default PrivateRoute;
