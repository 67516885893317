import SideBar from "./SideBar";
import Header from "./Notifications/Header";
import Content from "./Notifications/Content";
import Meta from "../Meta";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNotifications } from "../../slices/authSlice";
import { toast } from "react-toastify";

import { useGetNotificationsQuery } from "../../slices/notificationApiSlice";

import "../Shared/css/Wrapper.css";
import "../Shared/css/ContentContainer.css";

const ManagerNotificationsRoute = (props) => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const {
    data: notifications,
    isLoading: notificationLoader,
    error: notificationError,
  } = useGetNotificationsQuery(null, {
    pollingInterval: 3000
  });
  
  useEffect(() => {
    if (notificationError) {
      toast.error(
        notificationError?.data?.message ||
          "Network Error",
        { toastId: 'E2' }
      );
    }

    if (!notificationError && !notificationLoader) {
      dispatch(setNotifications(notifications.notifications));
    }
    
  }, [
    dispatch,
    notifications,
    notificationError,
    notificationLoader,
  ]);

  return (
    <div className="wrapper">
      <Meta title={"StockPilot - Manager Notifications"} />
      <SideBar id={props.id} />

      <div className="manager-content-container">
        <Header setSearch={setSearch} />
        {!notificationError && (
          <Content search={search} loading={notificationLoader} />
        )}
      </div>
    </div>
  );
};

export default ManagerNotificationsRoute;
