import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userProfile: localStorage.getItem("userProfile")
    ? JSON.parse(localStorage.getItem("userProfile"))
    : null,
  employees: [],
  notifications: [],
  stores: [],
  categories: [],
  items: [],
  customers: []
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.userProfile = action.payload;
      localStorage.setItem("userProfile", JSON.stringify(action.payload));
    },
    setExpirationTime: (state, action) => {
      localStorage.setItem("expirationTime", Date.now() + 24 * 60 * 60 * 1000);
    },
    logout: (state, action) => {
      state.userProfile = null;
      state.employees = [];
      state.stores = [];
      state.notifications = [];
      localStorage.removeItem("userProfile");
      localStorage.removeItem("expirationTime");
    },
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    setStores: (state, action) => {
      state.stores = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
  },
});

export const {
  setProfile,
  logout,
  setEmployees,
  setBoards,
  setStores,
  setExpirationTime,
  setNotifications,
  setCategories,
  setItems,
  setCustomers
} = authSlice.actions;

export default authSlice.reducer;
