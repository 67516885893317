import React from "react";

function LandingPage() {
  return (
    <div>
      <h1 className="text-xl text-blue">landing page in progress</h1>
    </div>
  );
}

export default LandingPage;
