import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import slideShow1 from "../images/slideshow/Group_3.jpg";
import slideShow2 from "../images/slideshow/Group_4.jpg";
import slideShow3 from "../images/slideshow/Group_5.jpg";

// import required modules
import {
  Keyboard,
  Mousewheel,
  Autoplay,
  Parallax,
  Pagination,
  Navigation,
} from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";

import { useLoginMutation } from "../slices/userApiSlice";
import { setProfile, setExpirationTime } from "../slices/authSlice";

import Loader from "../components/Loader";
import Meta from "../components/Meta";

const slideshow = [
  {
    imageSrc: slideShow1,
    caption1: "Monitor your business from the",
    emphasis: "properly",
    caption2: "of your home",
  },
  {
    imageSrc: slideShow2,
    caption1: "Make sure everything is",
    emphasis: "properly",
    caption2: "accounted",
  },
  {
    imageSrc: slideShow3,
    caption1: "Make sure everything is",
    emphasis: "properly",
    caption2: "accounted",
  },
];

function LoginScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => state.auth);

  const [login, { isLoading: loginLoader }] = useLoginMutation();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (userProfile && userProfile.role === "admin") navigate("/admin/home");
    else if (userProfile && userProfile.role === "manager")
      navigate("/manager/home");
    else if (userProfile && userProfile.role === "cashier")
      navigate("/cashier/home");
  }, [navigate, userProfile]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const res = await login(user).unwrap();
      toast.success(res.message);
      dispatch(setProfile(res.user));
      navigate("/" + res.user.role + "/home");
      dispatch(setExpirationTime());
    } catch (error) {
      toast.error(error?.data?.message || "Network Error");
    }

    setUser({ username: "", password: "" });
  };

  return (
    <>
      {loginLoader && <Loader />}
      <Meta />
      <section className="login flex h-screen w-full items-center justify-center gap-x-20 bg-white bg-doodle-pattern">
        <div id="sign-in" className="flex flex-col items-center">
          <img id="logo" src="/images/Logo.svg" alt="logo" className="w-64" />

          <h2 className="mb-20 mt-8 text-4xl font-semibold">Login</h2>

          <form onSubmit={handleSubmit} className="text-center text-xl">
            <input
              className="mb-16 w-96 border-b-2 border-b-black bg-transparent focus:outline-none"
              type="text"
              id="username"
              name="username"
              value={user.username}
              placeholder="Username or Email"
              onChange={handleInputChange}
              required
            />
            <br />
            <div className="relative mb-8 flex">
              <input
                className="w-96 border-b-2 border-b-black bg-transparent focus:outline-none"
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                value={user.password}
                placeholder="Password"
                onChange={handleInputChange}
                required
              />
              {passwordVisible ? (
                <AiFillEyeInvisible
                  className="absolute bottom-0 right-0 mb-1 cursor-pointer text-3xl text-blue"
                  onClick={() => setPasswordVisible(false)}
                />
              ) : (
                <AiFillEye
                  className="absolute bottom-0 right-0 mb-1 cursor-pointer text-3xl text-blue"
                  onClick={() => setPasswordVisible(true)}
                />
              )}
            </div>
            <br />
            <Link
              to="/forgot"
              id="forgot-password"
              className="underline hover:font-semibold"
            >
              Forgot Password?
            </Link>
            <br />

            <input
              type="submit"
              id="submit"
              value="Login"
              className="mt-20 cursor-pointer rounded-full border-2 border-blue bg-blue px-24 py-3 font-semibold text-accentpurple outline-none hover:bg-accentpurple hover:text-blue"
            />
          </form>
        </div>

        {/* CAROUSEL IMAGES */}
        <div
          id="carousel-part"
          className="hidden 2xl:w-3/12 lg:block lg:w-4/12"
        >
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            centeredSlides={true}
            mousewheel={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            keyboard={{
              enabled: true,
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            modules={[
              Keyboard,
              Autoplay,
              Parallax,
              Mousewheel,
              Pagination,
              Navigation,
            ]}
            className="mySwiper"
          >
            {slideshow.map((slide) => (
              <SwiperSlide className="relative">
                <img src={slide.imageSrc} alt="slide" />
                <div className="absolute inset-x-0 bottom-0 mb-12">
                  <p className="text-accentpurple 2xl:text-3xl xl:px-8 lg:px-6 lg:text-2xl">
                    {slide.caption1} <strong>{slide.emphasis}</strong>{" "}
                    {slide.caption2}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default LoginScreen;
