const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const USERS_URL = "/api/users";
const EMPLOYEES_URL = "/api/employees";
const NOTIFICATIONS_URL = "/api/notifications";
const STORES_URL = "/api/stores";
const CATEGORIES_URL = "/api/categories";
const ITEMS_URL = "/api/items";
const SALES_URL = "/api/sales";
const CUSTOMERS_URL = "/api/customers";
const DASHBOARD_URL = "/api/dashboard";

export {
  BASE_URL,
  USERS_URL,
  EMPLOYEES_URL,
  NOTIFICATIONS_URL,
  STORES_URL,
  CATEGORIES_URL,
  ITEMS_URL,
  SALES_URL,
  CUSTOMERS_URL,
  DASHBOARD_URL,
};
