import UserAvatar from "../../Shared/UserAvatar";
import HeaderSearch from "../../Shared/HeaderSearch";

const Header = (props) => {
  return (
    <div className="">
      <nav className="flex items-center justify-between rounded-2xl bg-white py-2 lg:px-4 2xl:px-8">
        <div className="">
          <HeaderSearch
            placeholder="Search notifications"
            setSearch={props.setSearch}
          />
        </div>

        <div className="user-avatar">
          <UserAvatar />
        </div>
      </nav>
    </div>
  );
};

export default Header;
