import { createSlice } from "@reduxjs/toolkit";
import { updateCart } from "../utils/cartUtils";

const initialState = {
  cartItems: [],
  paymentDetails: { method: "Mobile Money" },
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload;

      const existItem = state.cartItems.find(
        (foundItem) => foundItem.store_item_id === item.store_item_id,
      );

      if (existItem) {
        if (item.new) {
          if (item.stock_left >= existItem.qty + item.qty) {
            const modifiedItem = { ...item, qty: existItem.qty + item.qty };
            state.cartItems = state.cartItems.map((foundItem) =>
              foundItem.store_item_id === existItem.store_item_id
                ? modifiedItem
                : foundItem,
            );
          }
        } else {
          if (item.stock_left >= item.qty) {
            state.cartItems = state.cartItems.map((foundItem) =>
              foundItem.store_item_id === existItem.store_item_id
                ? item
                : foundItem,
            );
          }
        }
      } else {
        state.cartItems = [...state.cartItems, item];
      }

      return updateCart(state, item);
    },
    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (foundItem) => foundItem.store_item_id !== action.payload,
      );
      return updateCart(state);
    },
    setPaymentDetails: (state, action) => {
      state.paymentDetails = action.payload;
      return state;
    },
    clearCartItems: (state, action) => {
      state.cartItems = [];
      state.paymentDetails = { method: "Mobile Money" };
      return updateCart(state);
    },
  },
});

export const { addToCart, removeFromCart, setPaymentDetails, clearCartItems } =
  cartSlice.actions;

export default cartSlice.reducer;
