import { useEffect, useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { FaBarcode } from "react-icons/fa";

// import "./OrderDetails.css";
import CustomWebcam from "../../CustomWebcam";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, clearCartItems, removeFromCart } from "../../../slices/cartSlice";
import { toast } from "react-toastify";

const OrderDetails = (props) => {
  const { cartItems, totalPrice, itemsPrice, taxPrice } = useSelector(state => state.cart);
  const dispatch = useDispatch();
  // const [arrowDirection, setArrowDirection] = useState('forward');
  const [webcam, setWebcam] = useState(false);

  // const [enable, setEnable] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    props.setPaymentMenu(true);
  };

  const addToCartHandler = (item, qty) => {
    if(item.stock_left >= qty) {
      dispatch(addToCart({ ...item, qty, new: false }));
    }
    else
      toast.info("Quantity is too high", { toastId: 'I1' });
  }

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  }

  const clearCartItemsHandler = () => {
    dispatch(clearCartItems());
  }

  return (
    <div className="order-details flex h-full w-full flex-col overflow-scroll rounded-2xl bg-white p-4">
      <form
        onSubmit={handleSubmit}
        className="flex h-full flex-col justify-between gap-y-2 "
      >
        <section className="ordered-products h-full space-y-2 overflow-y-scroll">
          {cartItems.map((item, index) => (
            <div className="product rounded-xl bg-accentpurple p-3">
              <div className="summary flex w-full items-center justify-between gap-x-2 font-medium">
                <div className="w-full truncate">
                  <p className="truncate">{item.name}</p>
                </div>

                <div className="flex w-36 items-center justify-between gap-2 font-medium">
                  <p>GH¢ {item.selling_price}</p>
                  <GrFormClose
                    onClick={() => removeFromCartHandler(item.store_item_id)}
                    className="cursor-pointer rounded-full bg-fadegrey text-xl"
                  />
                </div>
              </div>

              <div className="more-info flex justify-between lg:mt-1 2xl:mt-3">
                <div>
                  <label htmlFor="quantity" className="font-mediun">
                    Quantity
                  </label>
                  {/* <br /> */}
                  <input
                    className="ml-4 w-2/5 p-1 outline-none"
                    type="number"
                    min="1"
                    max={item.stock_left}
                    onChange={(e) => {
                        addToCartHandler(item, Number(e.target.value));
                      }}
                    step="1"
                    name={item.name}
                    value={parseInt(item.qty)}
                    id="quantity"
                    onFocus={(e) => e.target.select()}
                    onClick={(e) => e.target.select()}
                    required
                  />
                </div>
              </div>
            </div>
          ))}
        </section>

        <section className="total-amount">
          <div className="flex justify-end">
            {/* <FaBarcode onClick={() => setWebcam(true)}/> */}
          </div>
          <div className="rounded bg-accentpurple px-4 py-3 font-semibold lg:my-2 2xl:my-3">
            <p className="flex justify-between">
              <span>Sub total</span> GH¢ {itemsPrice}
            </p>
            <p className="flex justify-between lg:my-0 2xl:my-1">
              <span>Tax</span> GH¢ {taxPrice}
            </p>
            <p className="flex justify-between">
              <span>Payable amount</span>
              GH¢ {totalPrice}
            </p>
          </div>
          <div className="flex justify-end gap-8">
            <button
              className="cursor-pointer rounded-lg bg-blue font-semibold text-white hover:bg-lighterpurple lg:px-2 lg:py-2 2xl:px-4 2xl:py-3"
              type="button"
              onClick={clearCartItemsHandler}
            >
              Clear All
            </button>
            <button
              className="cursor-pointer rounded-lg bg-blue font-semibold text-white hover:bg-lighterpurple lg:px-2 lg:py-2 2xl:px-4 2xl:py-3"
              type="submit"
              disabled={cartItems.length ? false : true}
            >
              Proceed
            </button>
          </div>
        </section>
      </form>

      {webcam ? <CustomWebcam setWebcam={setWebcam} /> : null}
    </div>
  );
};

export default OrderDetails;
