import "../Shared/css/SideBar.css";
import Logo from "../../images/Logo3.svg";
import Loader from "../Loader";
import UserManual from "../StockPilot_User_Manual.pdf";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useLogoutMutation } from "../../slices/userApiSlice";
import { logout } from "../../slices/authSlice";

import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiSolidUser, BiSolidHelpCircle } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";

const links = [
  {
    name: "Items",
    icon: <AiOutlineShoppingCart />,
    path: "/cashier/home",
  },
  {
    name: "Customers",
    icon: <BiSolidUser />,
    path: "/cashier/customers",
  },
  // {
  //     name: "Connections",
  //     icon: <FaWifi/>,
  //     path: "/cashier/connections"
  // }
];

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => state.auth);

  const [logOut, { isLoading: logoutLoader }] = useLogoutMutation();
  const handleLogout = async () => {
      dispatch(logout());
      navigate("/login");
      const res = await logOut({ id: userProfile.user_id }).unwrap();

      toast.success(res.message);
  };

  return (
    <div>
      {logoutLoader && <Loader />}
      <div className="cashier-sidebar mx-2 flex h-full flex-col justify-center rounded-3xl bg-blue px-8 text-accentpurple lg:w-52 lg:gap-y-20 2xl:w-60 2xl:gap-y-32">
        <div className="flex flex-col items-center justify-center">
          <img src={Logo} alt="company logo" className=" lg:w-32 2xl:w-36" />
          <h3 className="mt-1 lg:text-base 2xl:text-lg">
            {userProfile.business_name}
          </h3>
        </div>

        <div className="links flex flex-col lg:gap-y-2 2xl:gap-y-4">
          {links.map((link) => (
            <NavLink
              to={link.path}
              className={
                "flex flex-col items-center justify-center px-4 py-4 text-accentpurple lg:gap-y-2 2xl:gap-y-3 2xl:text-lg"
              }
            >
              {link.icon} {link.name}
            </NavLink>
          ))}
        </div>

        <div className="bottom">
          <Link
            className="log-out flex items-center justify-center gap-2 hover:underline hover:underline-offset-4 lg:mb-3  lg:text-lg 2xl:mb-8 2xl:text-xl"
            to="#"
            onClick={handleLogout}
          >
            <FiLogOut />
            Log out
          </Link>

          <div className="help flex justify-center font-semibold hover:underline hover:underline-offset-4">
            <a
              href={UserManual}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 lg:text-lg 2xl:text-xl"
            >
              <BiSolidHelpCircle />
              Need Help
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
