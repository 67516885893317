import SideBar from "./SideBar";
import Header from "./Store/Header";
import Content from "./Store/Content";
import Meta from "../Meta";
import Loader from "../Loader";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setEmployees, setStores } from "../../slices/authSlice";
import { toast } from "react-toastify";

import { useGetEmployeesQuery } from "../../slices/employeeApiSlice";
import { useGetStoresQuery } from "../../slices/storeApiSlice";

import "../Shared/css/Wrapper.css";
import "../Shared/css/ContentContainer.css";

const AdminStoreRoute = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const {
    data: employees,
    isLoading: employeeLoader,
    error: employeeError,
  } = useGetEmployeesQuery(null, {
    pollingInterval: 5 * 1000, // Refetch every 5 seconds
  });
  const {
    data: stores,
    isLoading: storeLoader,
    error: storeError,
  } = useGetStoresQuery(null, {
    pollingInterval: 5 * 1000, // Refetch every 5 seconds
  });

  useEffect(() => {
    if (storeError) {
      toast.error(
        employeeError?.data?.message ||
          storeError?.data?.message ||
          "Network Error",
        { toastId: 'E1' }
      );
    }

    if (!storeError && !storeLoader) {
      dispatch(setStores(stores.stores));
    }
    if (!employeeError && !employeeLoader) {
      dispatch(setEmployees(employees.employees));
    }
  }, [
    dispatch,
    employees,
    stores,
    employeeError,
    storeError,
    employeeLoader,
    storeLoader,
  ]);

  return (
    <div className="wrapper">
      {storeLoader && <Loader />}
      <Meta title={"StockPilot - Admin Stores"} />
      <SideBar />

      <div className="admin-content-container">
        <Header setSearch={setSearch} />
        {!storeError && (
          <Content search={search} loading={storeLoader} />
        )}
      </div>
    </div>
  );
};

export default AdminStoreRoute;
