export const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
};
  
export const updateCart = (state) => {
    // Calculate the items price
    state.itemsPrice = addDecimals(
        state.cartItems.reduce((acc, item) =>
            acc + item.selling_price * item.qty - item.selling_price * item.qty * 0.01,
        0)
    );

    state.itemsOriginalPrice = addDecimals(
        state.cartItems.reduce((acc, item) =>
            acc + item.cost_price * item.qty,
        0)
    )

    // Calculate the tax price
    // state.taxPrice = addDecimals(Number((0.15 * state.itemsPrice).toFixed(2)));
    state.taxPrice = addDecimals(
        state.cartItems.reduce((acc, item) => acc + item.selling_price * item.qty * 0.01, 0)
    );

    // Calculate the total price
    state.totalPrice = (
        Number(state.itemsPrice) +
        Number(state.taxPrice)
    ).toFixed(2);

    state.paymentDetails.balance = -state.totalPrice;
    state.paymentDetails.amount = 0.0;

    return state;
};