import UserAvatar from "../../Shared/UserAvatar";
import { ImArrowLeft2 } from "react-icons/im";

const BackHeader = (props) => {
  return (
    <div className="">
      <nav class="flex items-center justify-between rounded-2xl bg-white px-8 py-2">
        <div
          className="flex cursor-pointer items-center gap-x-1 text-2xl"
          onClick={() => {
            props.setPaymentMenu(false);
          }}
        >
          <ImArrowLeft2 /> Back
        </div>

        <div className="user-avatar">
          <UserAvatar />
        </div>
      </nav>
    </div>
  );
};

export default BackHeader;
