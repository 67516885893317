import Content from "./Customers/Content";
import Header from "./Customers/Header";
import SideBar from "./SideBar";
import Meta from "../Meta";

import "../Shared/css/Wrapper.css";
import "../Shared/css/ContentContainer.css";

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useGetCustomersQuery } from "../../slices/customerApiSlice";
import { setCustomers } from "../../slices/authSlice";

const CashierCustomerRoute = (props) => {
  const dispatch = useDispatch();
  
  const [search, setSearch] = useState("");

  const { data: customers, isLoading: customerLoading, error: customerError, refetch } = useGetCustomersQuery(null, { pollingInterval: 5000 });

  useEffect(() => {
    if (customerError) toast.error(customerError?.data?.message || "Network Error", { toastId: 'E1' });

    if (!customerError && !customerLoading) {
      dispatch(setCustomers(customers?.customers));
    }

  }, [customerError, customerLoading, customers]); 

  return (
    <div className="wrapper">
      <Meta title={"StockPilot - Cashier Customers"} />
      <SideBar id={props.id} />

      <div className="cashier-content-container">
        <Header setSearch={setSearch}/>
        <Content id={props.id} search={search} loading={customerLoading} refetch={refetch}/>
      </div>
    </div>
  );
};

export default CashierCustomerRoute;
