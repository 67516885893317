import UserAvatar from "../../Shared/UserAvatar";
import HeaderSearch from "../../Shared/HeaderSearch";

const Header = (props) => {
  return (
    <div className="">
      <nav className="flex sm:gap-2 items-center justify-between rounded-2xl bg-white py-2 shadow-lg 2xl:px-8 lg:px-4 sm:rounded-md sm:p-2">
        <div className="">
          <HeaderSearch
            placeholder="Search employees"
            setSearch={props.setSearch}
          />
        </div>

        <div className="user-avatar ">
          <UserAvatar />
        </div>
      </nav>
    </div>
  );
};

export default Header;
