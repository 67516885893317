import "./EmployeeProfile.css";
import "../../../styles/css/Popup.css";
import DeleteConfirmation from "../../Alerts/DeleteConfirmation";

import { FaTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { BsFillBookmarkFill } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { useState } from "react";
import Loader from "../../Loader";
import { capitalize } from "lodash";

import { useUpdateEmployeeMutation } from "../../../slices/employeeApiSlice";
import { toast } from "react-toastify";

const EmployeeProfile = (props) => {
  const [employeeDetails, setEmployeeDetails] = useState({
    ...props.info,
  });

  const [isReadOnly, setReadOnly] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  const [updateEmployee, { isLoading: updateLoader }] =
    useUpdateEmployeeMutation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setEmployeeDetails((previousProps) => ({
      ...previousProps,
      [name]: value,
    }));
  };

  const deleteAction = () => {
    props.delete(employeeDetails.user_id);
    setConfirmDelete(false);
    setDeletePopup(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const res = await updateEmployee({
        data: employeeDetails,
        id: employeeDetails.user_id,
      }).unwrap();
      props.alertState([true, [res.message]]);
      props.formState(false);
      setReadOnly(true);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.message || "Network Error");
    }
  };

  return (
    <div className="popup-container">
      <section className="admin-employee-profile rounded-2xl bg-white p-8 pb-6">
        <h2 className="mx-auto flex h-28 w-28 items-center justify-center rounded-full bg-blue text-7xl font-semibold text-white">
          {employeeDetails.first_name[0]}
        </h2>

        <form className="mt-8 flex justify-center gap-x-8">
          <div className="left">
            <label for="first_name">First Name</label>
            <br />
            <input
              type="text"
              id="full-name"
              name="first_name"
              value={employeeDetails.first_name}
              onChange={handleInputChange}
              readOnly={isReadOnly}
            />
            <br />

            <label for="other_name">Other Name</label>
            <br />
            <input
              type="text"
              id="full-name"
              name="other_name"
              value={employeeDetails.other_name}
              onChange={handleInputChange}
              readOnly={isReadOnly}
            />
            <br />

            <label for="last_name">Last Name</label>
            <br />
            <input
              type="text"
              id="full-name"
              name="last_name"
              value={employeeDetails.last_name}
              onChange={handleInputChange}
              readOnly={isReadOnly}
            />
            <br />

            <label for="store_id">Store Name</label>
            <br />
            <input
              type="text"
              id="store-id"
              name="store_id"
              value={employeeDetails.store_name}
              onChange={handleInputChange}
              readOnly
              required
            />
            <br />

            <label for="email">Email</label>
            <br />
            <input
              type="email"
              id="email"
              name="email"
              value={employeeDetails.email}
              onChange={handleInputChange}
              readOnly={isReadOnly}
              required
            />
            <br />

            <div className="edit">
              <div>
                {isReadOnly ? (
                  <button
                    className="flex cursor-pointer items-center gap-x-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
                    type="button"
                    onClick={() => setReadOnly(false)}
                  >
                    <FiEdit /> Edit
                  </button>
                ) : (
                  <button
                    className="flex cursor-pointer items-center gap-x-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
                    type="button"
                    onClick={(event) => {
                      handleSubmit(event);
                    }}
                  >
                    <BsFillBookmarkFill /> Save
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="right">
            <label for="username">Username</label>
            <br />
            <input
              type="text"
              id="username"
              name="username"
              value={employeeDetails.username}
              onChange={handleInputChange}
              readOnly
              required
            />
            <br />

            <label for="position">Position</label>
            <br />
            <input
              type="text"
              id="position"
              name="position"
              value={capitalize(employeeDetails.role)}
              onChange={handleInputChange}
              readOnly
              required
            />
            <br />

            <label for="phone_number">Mobile Number</label>
            <br />
            <input
              type="tel"
              id="mobile-number"
              name="phone_number"
              value={employeeDetails.phone_number}
              onChange={handleInputChange}
              readOnly={isReadOnly}
              required
            />
            <br />

            <label for="address">Digital Address</label>
            <br />
            <input
              type="text"
              id="address"
              name="address"
              value={employeeDetails.address}
              onChange={handleInputChange}
              readOnly={isReadOnly}
              required
            />
            <br />

            <label for="salary">Salary (GH¢)</label>
            <br />
            <input
              type="number"
              id="salary"
              name="salary"
              value={employeeDetails.salary}
              onChange={handleInputChange}
              readOnly={isReadOnly}
              required
            />

            <div className=" flex justify-between ">
              <button
                className="flex cursor-pointer items-center gap-x-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
                type="button "
                onClick={() => props.formState(false)}
              >
                <CgClose /> Close
              </button>
              <button
                className="flex cursor-pointer items-center gap-x-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
                type="button"
                onClick={() => setDeletePopup(true)}
              >
                <FaTrashAlt />
                Delete
              </button>
            </div>
          </div>
        </form>
      </section>

      {updateLoader && <Loader />}
      {deletePopup ? (
        <DeleteConfirmation
          popupState={setDeletePopup}
          confirm={deleteAction}
        />
      ) : null}
    </div>
  );
};

export default EmployeeProfile;

/* 

DETAILS YOU CAN EDIT

Under that, you can change the:
Location
Name
Store Contact
Manager

*/
