import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import {
  useConfirmTokenQuery,
  useAlterPasswordMutation,
} from "../slices/userApiSlice";

import Meta from "../components/Meta";
import Loader from "../components/Loader";

const ResetScreen = () => {
  const params = useParams();
  const navigate = useNavigate();

  if (
    params?.type?.toLowerCase() !== "reset" &&
    params?.type?.toLowerCase() !== "set"
  ) {
    toast.error("Invalid Link", { toastId: "E1" });
    navigate("/login");
  }

  const {
    data,
    isLoading: confirmLoader,
    isError,
    error,
  } = useConfirmTokenQuery({ token: params.token, type: params.type });
  const [alterPassword, { isLoading: alterLoader }] =
    useAlterPasswordMutation();

  const [alter, setAlter] = useState(0);
  const [id, setId] = useState(0);
  const [password, setPassword] = useState({
    new: "",
    confirm: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (!params.token || !params.type) {
      toast.error("Invalid Link");
      navigate("/login");
    }

    if (isError) {
      toast.error(error?.data?.message || error?.error);
      navigate("/login");
    } else {
      if (!confirmLoader) {
        data?.id && setId(data?.id);
        data?.valid && setAlter(1);
      }
    }
  }, [params, navigate, confirmLoader, data, error, isError]);

  const handlePassword = (e) => {
    const { name, value } = e.target;

    setPassword((prevPassword) => ({
      ...prevPassword,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (password.new !== password.confirm) {
        toast.error("Passwords do not match");
      } else {
        const data = {
          password: password.new,
          id: id,
        };

        const res = await alterPassword({ data, type: params.type }).unwrap();
        toast.success(res.message);
        setAlter(2);
      }
    } catch (error) {
      toast.error(error?.data?.message || "Network Error");
    }

    setPassword({ new: "", confirm: "" });
  };

  return (
    <>
      {confirmLoader && <Loader />}
      {id !== 0 && (
        <div className="reset h-screen w-full bg-white bg-doodle-pattern">
          {alterLoader && <Loader />}
          <Meta title={"StockPilot - Reset Password"} />
          {alter === 1 && (
            <section
              id="reset-password-1"
              className="flex h-screen items-center justify-center lg:gap-x-12 2xl:gap-x-20"
            >
              <div id="left" className="lg:w-2/6 2xl:w-1/5">
                <img
                  src="/images/Logo.svg"
                  alt="site logo"
                  className="mx-auto mb-32"
                />

                <h2 className="text-5xl font-semibold text-blue">
                  Create a new password
                </h2>
                <p className="mb-12 mt-8 text-2xl text-fadedblack">
                  Enter a new password below
                </p>

                <form onSubmit={handleSubmit} className="flex flex-col text-xl">
                  <div className="relative flex">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      name="new"
                      id="new-password"
                      placeholder="New password"
                      minLength={8}
                      value={password.new}
                      onChange={handlePassword}
                      required
                      className="mt-3 w-full border-b-2 border-b-black bg-transparent pb-1 focus:outline-none"
                    />
                    {passwordVisible ? (
                      <AiFillEyeInvisible
                        className="absolute bottom-0 right-0 mb-1 cursor-pointer text-3xl text-blue"
                        onClick={() => setPasswordVisible(false)}
                      />
                    ) : (
                      <AiFillEye
                        className="absolute bottom-0 right-0 mb-1 cursor-pointer text-3xl text-blue"
                        onClick={() => setPasswordVisible(true)}
                      />
                    )}
                  </div>
                  <br />

                  <div className="relative flex">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      name="confirm"
                      id="confirm-password"
                      placeholder="Confirm password"
                      value={password.confirm}
                      onChange={handlePassword}
                      minLength={8}
                      required
                      className="mt-16 w-full border-b-2 border-b-black bg-transparent pb-1 focus:outline-none"
                    />
                    {passwordVisible ? (
                      <AiFillEyeInvisible
                        className="absolute bottom-0 right-0 mb-1 cursor-pointer text-3xl text-blue"
                        onClick={() => setPasswordVisible(false)}
                      />
                    ) : (
                      <AiFillEye
                        className="absolute bottom-0 right-0 mb-1 cursor-pointer text-3xl text-blue"
                        onClick={() => setPasswordVisible(true)}
                      />
                    )}
                  </div>
                  <br />

                  <input
                    type="submit"
                    value="Create"
                    id="create-button"
                    className=" mx-auto mt-20 cursor-pointer rounded-full border-2 border-blue bg-blue px-20 py-3 text-xl font-semibold text-accentpurple outline-none hover:bg-accentpurple hover:text-blue"
                  />
                </form>
              </div>

              <div id="right" className="hidden lg:block lg:w-3/6 2xl:w-2/6">
                <img src="/images/forgot_password_1.svg" alt="" />
              </div>
            </section>
          )}
          {alter === 2 && (
            <section
              id="reset-password-2"
              className="flex h-screen items-center justify-center lg:gap-x-12 2xl:gap-x-20"
            >
              <div id="left" className="lg:w-2/5 2xl:w-3/12">
                <img
                  src="/images/Logo.svg"
                  alt="site logo"
                  className="mx-auto mb-16"
                />

                <h2 className="text-5xl font-semibold text-blue">
                  Password created successfully
                </h2>
                <p className="mb-12 mt-10 text-2xl text-fadedblack">
                  You have successfully created your new password. Please use
                  your new password to login.
                </p>

                <form onSubmit={() => navigate("/login")} className="flex">
                  <input
                    type="submit"
                    value="Login"
                    id="login-button"
                    className=" mx-auto mt-32 cursor-pointer rounded-full border-2 border-blue bg-blue px-20 py-3 text-xl font-semibold text-accentpurple outline-none hover:bg-accentpurple hover:text-blue"
                  />
                </form>
              </div>

              <div id="right" className="hidden lg:block lg:w-3/6 2xl:w-2/6">
                <img src="/images/forgot_password_3.svg" alt="" />
              </div>
            </section>
          )}
        </div>
      )}
    </>
  );
};
export default ResetScreen;
