import { apiSlice } from "./apiSlice";
import { USERS_URL } from "../constants";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => ({
        url: `${USERS_URL}/profile`,
      }),
      providesTags: ["User"],
    }),
    login: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/login`,
        method: "POST",
        body: data,
      }),
    }),
    verifyUser: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/verify`,
        method: "POST",
        body: data,
      }),
    }),
    confirmToken: builder.query({
      query: ({ token, type }) => ({
        url: `${USERS_URL}/${type}/password/${token}`,
      }),
    }),
    alterPassword: builder.mutation({
      query: ({ data, type }) => ({
        url: `${USERS_URL}/${type}/password`,
        method: "POST",
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: (data) => ({
        url: `${USERS_URL}/logout`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Employee", "Store", "User"],
    }),
  }),
});

export const {
  useLoginMutation,
  useVerifyUserMutation,
  useConfirmTokenQuery,
  useGetProfileQuery,
  useAlterPasswordMutation,
  useLogoutMutation,
} = userApiSlice;
