import ContentLoader from "react-content-loader";
import EmployeeProfile from "./EmployeeProfile";
import Loader from "../../Loader";
import DoneAlert from "../../Alerts/DoneAlert";
import DeleteConfirmation from "../../Alerts/DeleteConfirmation";

import { GrCheckbox, GrCheckboxSelected } from "react-icons/gr";
import { MdEmail, MdCall } from "react-icons/md";
import { useState } from "react";
import { useDeleteEmployeeMutation } from "../../../slices/employeeApiSlice";
import { toast } from "react-toastify";

const MAX_LENGTH = {
  nameLength: 13,
  storeLength: 12,
  emailLength: 22,
};

const Cards = (props) => {
  const [employeeProfile, setEmployeeProfile] = useState(false);
  const [active, setActive] = useState(false);
  const [alert, setAlert] = useState([false]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  const [deleteEmployee, { isLoading: deleteLoader }] =
    useDeleteEmployeeMutation();

  const select = () => {
    console.log(props.id);
    props.setSelected([...props.selected, props.id]);
    setActive(true);
  };
  const selectStyle = {
    border: "solid 1px var(--lighter-purple)",
    borderRadius: "1rem",
  };

  const unselect = () => {
    props.setSelected(props.selected.filter((index) => index !== props.id));
    setActive(false);
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteEmployee(id).unwrap();
      setAlert([true, [res.message]]);

      setEmployeeProfile(false);
    } catch (error) {
      toast.error(error?.data?.message || error?.error);
    }
  };

  const callDelete = () => {
    handleDelete(props.info.user_id);
    setConfirmDelete(false);
    setDeletePopup(false);
  }

  return props.loading ? (
    <section className="">
      <ContentLoader
        speed={1}
        interval={0.15}
        width={290} // Set the width to 290px
        height={310} // Set the height to 310px
        viewBox="0 0 290 310" // Adjust the viewBox accordingly
        backgroundColor="var(--accent-purple)" // Set the background color to #F5EDFF
        foregroundColor="var(--background-color)"
      >
        <rect x="0" y="0" rx="16" ry="16" width="290" height="310" />{" "}
        {/* Adjust border radius */}
      </ContentLoader>
    </section>
  ) : (
    <section
      className="rounded-2xl bg-accentpurple p-2 hover:shadow-xl lg:w-80 2xl:w-[21.7rem]"
      style={active ? selectStyle : null}
    >
      <div className=" flex w-full items-center justify-between text-white">
        <div className="flex items-center gap-x-2">
          <div className="relative">
            <GrCheckbox onClick={select} className="text-2xl" />

            {props.selected.map((index) =>
              index === props.id ? (
                <GrCheckboxSelected
                  onClick={unselect}
                  className="absolute top-0 text-2xl"
                />
              ) : null,
            )}
          </div>

          <p
            className="rounded-md px-3 py-1 uppercase"
            style={
              props.info.is_active
                ? { backgroundColor: "var(--lighter-purple)" }
                : { backgroundColor: "var(--blue)" }
            }
          >
            {props.info.is_active ? "active" : "inactive"}
          </p>
        </div>

        <p
          className="cursor-pointer rounded-md bg-blue px-2 py-1 text-white hover:bg-lighterpurple"
          onClick={() => setDeletePopup(true)}
        >
          Delete
        </p>
      </div>

      <section
        id={props.id}
        className="flex cursor-pointer flex-col items-center bg-accentpurple"
        onClick={() => {
          setEmployeeProfile(true);
        }}
      >
        <div className="flex flex-col items-center">
          <p className="initial mb-1 flex h-20 w-20 items-center justify-center rounded-full bg-blue text-5xl font-semibold text-white">
            {props.info.first_name[0]}
          </p>
          <p className="name text-2xl font-semibold capitalize">
            {(props.info.first_name + " " + props.info.last_name).length >
            MAX_LENGTH.nameLength
              ? (props.info.first_name + " " + props.info.last_name).slice(
                  0,
                  MAX_LENGTH.nameLength,
                ) + " ..."
              : props.info.first_name + " " + props.info.last_name}
          </p>
          <p className="position capitalize">{props.info.role}</p>
          <hr className="border-1 mx-auto mb-8 mt-4 w-full border-blue" />
        </div>

        <div className="flex w-full justify-between gap-x-4 text-center">
          <div className="div1 w-full rounded-lg bg-white px-2 py-2">
            <h2 className="text-base font-semibold">Employee ID</h2>
            <p className="text-sm text-fadedblack">{props.info.user_id}</p>
          </div>

          <div className="div2 w-full rounded-lg bg-white px-2 py-2">
            <h2 className="text-base font-semibold"> Store </h2>
            <p className="text-sm text-fadedblack">
              {props.info.store_name
                ? props.info.store_name.length > MAX_LENGTH.storeLength
                  ? props.info.store_name.slice(0, MAX_LENGTH.storeLength) +
                    "..."
                  : props.info.store_name
                : "N/A"}
            </p>
          </div>
        </div>

        <div className="mt-4 w-full rounded-xl bg-white px-4 py-3 font-medium">
          <p className="mb-3 flex items-center gap-x-3">
            <MdEmail />{" "}
            {props.info.email.length > MAX_LENGTH.emailLength
              ? props.info.email.slice(0, MAX_LENGTH.emailLength) + " ..."
              : props.info.email}
          </p>
          <p className="flex items-center gap-x-3">
            <MdCall /> {props.info.phone_number}
          </p>
        </div>
      </section>

      {employeeProfile ? (
        <EmployeeProfile
          id={props.id}
          delete={handleDelete}
          formState={setEmployeeProfile}
          info={props.info}
          alertState={setAlert}
        />
      ) : null}

      {alert[0] ? (
        <DoneAlert alertMessage={alert[1]} alertState={setAlert} />
      ) : null}

      {deletePopup ? (
        <DeleteConfirmation
          popupState={setDeletePopup}
          confirm={callDelete}
        />
      ) : null}
      {deleteLoader && <Loader />}
    </section>
  );
};

export default Cards;
