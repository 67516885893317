function convertISODateToMMMDDYYYY(isoDate) {
    if(!isoDate)
        return;
    const dateObject = new Date(isoDate);
  
    const monthNames = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun",
      "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec"
    ];
  
    const month = monthNames[dateObject.getMonth()];
    const day = dateObject.getDate().toString().padStart(2, '0');
    const year = dateObject.getFullYear();
  
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
  }

  function convertISODateToMMDDYYYY(isoDate) {
    if(!isoDate)
        return;
    const dateObject = new Date(isoDate);
    
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-indexed
    const day = dateObject.getDate().toString().padStart(2, '0');
    const year = dateObject.getFullYear();
  
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }
  
  function convertISODateToYYYYMMDD(isoDate) {
    const dateObject = new Date(isoDate);
  
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-indexed
    const day = dateObject.getDate().toString().padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
    
  export {
    convertISODateToMMDDYYYY,
    convertISODateToMMMDDYYYY,
    convertISODateToYYYYMMDD
  }  