import { apiSlice } from "./apiSlice";
import { SALES_URL } from "../constants";

export const salesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSales: builder.query({
      query: () => ({
        url: `${SALES_URL}`,
      }),
      providesTags: ["Sale"],
    }),
    addSale: builder.mutation({
      query: (data) => ({
        url: `${SALES_URL}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Sale", "Item", "Notification"],
    }),
  }),
});

export const {
  useGetSalesQuery,
  useAddSaleMutation
} = salesApiSlice;
