import SideBar from "./SideBar";
import Header from "./Home/Header";
import Content from "./Home/Content";
import Meta from "../Meta";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setTop, setTopSelling, setRecentSales, setReports } from "../../slices/homeSlice";
import { toast } from "react-toastify";

import "../Shared/css/Wrapper.css";
import "../Shared/css/ContentContainer.css";
import { useGetRecentSalesQuery, useGetReportsQuery, useGetTopQuery, useGetTopSellingQuery } from "../../slices/dashboardApiSlice";
import Loader from "../Loader";

const AdminHomeRoute = () => {
  const dispatch = useDispatch();

  const {
    data: topStats,
    isLoading: topStatsLoader,
    error: topStatsError,
  } = useGetTopQuery(null, {
    pollingInterval: 3000,
  });

  useEffect(() => {
    if (topStatsError) {
      toast.error(topStatsError?.data?.message || "Network Error", {
        toastId: "E2",
      });
    }

    if (!topStatsError && !topStatsLoader) {
      dispatch(setTop(topStats));
    }
  }, [dispatch, topStats, topStatsError, topStatsLoader]);

  const {
    data: reports,
    isLoading: reportsLoader,
    error: reportsError,
  } = useGetReportsQuery(null, {
    pollingInterval: 3000,
  });

  useEffect(() => {
    if (reportsError) {
      toast.error(reportsError?.data?.message || "Network Error", {
        toastId: "E2",
      });
    }

    if (!reportsError && !reportsLoader) {
      dispatch(setReports(reports));
    }
  }, [dispatch, reports, reportsLoader, reportsError]);
  
  const {
    data: topSelling,
    isLoading: topSellingLoader,
    error: topSellingError,
  } = useGetTopSellingQuery(null, {
    pollingInterval: 3000,
  });

  useEffect(() => {
    if (topSellingError) {
      toast.error(topSellingError?.data?.message || "Network Error", {
        toastId: "E2",
      });
    }

    if (!topSellingError && !topSellingLoader) {
      dispatch(setTopSelling(topSelling.stats));
    }
  }, [dispatch, topSelling, topSellingLoader, topSellingError]);

  const {
    data: recentSales,
    isLoading: recentSalesLoader,
    error: recentSalesError,
  } = useGetRecentSalesQuery(null, {
    pollingInterval: 3000,
  });

  useEffect(() => {
    if (recentSalesError) {
      toast.error(recentSalesError?.data?.message || "Network Error", {
        toastId: "E2",
      });
    }

    if (!recentSalesError && !recentSalesLoader) {
      dispatch(setRecentSales(recentSales.stats));
    }
  }, [dispatch, recentSales, recentSalesLoader, recentSalesError]);

  return (
    <div className="wrapper sm:p-0 ">
      <Meta title={"StockPilot - Admin Home"} />
      <SideBar />

      <div className="admin-content-container">
        {reportsLoader && <Loader />}
        <Header />
        {!topStatsError &&
          !recentSalesError &&
          !topSellingError &&
          !reportsError &&
          !reportsLoader && (
            <Content
              loading={
                topStatsLoader ||
                recentSalesLoader ||
                topSellingLoader ||
                reportsLoader
              }
            />
          )}
      </div>
    </div>
  );
};

export default AdminHomeRoute;
