// import "../Header.css";

// import { AiOutlineSearch } from "react-icons/ai";
import HeaderSearch from "../../Shared/HeaderSearch";
import UserAvatar from "../../Shared/UserAvatar";

import { useSelector } from "react-redux";

const Header = (props) => {
  return (
    // <div className="cashier-navbar-container">
    <div className="">
      <nav className="flex items-center justify-between rounded-2xl bg-white px-8 py-2">
      <HeaderSearch
            placeholder="Search items"
            setSearch={props.setSearch}
          />
        <div className="user-avatar">
          <UserAvatar />
        </div>
      </nav>
    </div>
  );
};

export default Header;
