import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "",
  credentials: "include",
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: ["User", "Employee", "Store", "Notification", "Category", "Item", "Sale", "Customer"],
  endpoints: (builder) => ({}),
});
