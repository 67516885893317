import { useState } from "react";
import { toast } from "react-toastify";
import { useVerifyUserMutation } from "../slices/userApiSlice";

import Meta from "../components/Meta";
import Loader from "../components/Loader";

function ForgotScreen() {
  const [email, setEmail] = useState("");
  const [proceed, setProceed] = useState(false);
  const [verify, { isLoading: verifyLoader }] = useVerifyUserMutation();

  const handleSend = async (e) => {
    e.preventDefault();

    if (email.length === "") {
      setProceed(false);
      return;
    }

    try {
      const res = await verify({ email }).unwrap();
      setProceed(true);
      toast.success(res.message);
    } catch (error) {
      toast.error(error?.data?.message || "Network Error");
    }
  };

  return (
    <>
      {verifyLoader && <Loader />}
      <Meta title={"StockPilot - Forgot Password"} />

      <div className="forgot h-screen w-full bg-white bg-doodle-pattern">
        {!proceed ? (
          <section
            id="forgot-password-1"
            className="flex h-screen items-center justify-center lg:gap-x-12 2xl:gap-x-20"
          >
            <div id="left" className="lg:w-2/5 2xl:w-3/12">
              <img
                src="./images/Logo.svg"
                alt="site logo"
                className="mx-auto mb-16"
              />

              <h2 className="text-5xl font-semibold text-blue">
                Forgot your Password?
              </h2>
              <h3 className="mb-8 mt-4 text-3xl font-semibold text-fadedblack">
                Don't Worry, We Got You!
              </h3>
              <p className="my-4 text-xl text-fadedblack">
                Enter the email address associated with your account
              </p>

              <form onSubmit={handleSend} className="flex flex-col">
                <div
                  id="email-field"
                  className="mb-10 mt-6 rounded-xl bg-accentpurple px-8 py-4 font-semibold text-blue"
                >
                  <label htmlFor="email">Email</label>
                  <br />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Eg. johndoe@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="mt-3 w-full border-b-2 border-b-black bg-transparent pb-1 focus:outline-none"
                  />
                </div>

                <input
                  type="submit"
                  value="Reset Password"
                  id="reset-button"
                  className="mx-auto cursor-pointer rounded-full border-2 border-blue bg-blue px-8 py-3 text-xl font-semibold text-accentpurple outline-none hover:bg-accentpurple hover:text-blue"
                />
              </form>
            </div>

            <div id="right" className="hidden lg:block lg:w-3/6 2xl:w-2/6">
              <img src="./images/forgot_password_1.svg" alt="" />
            </div>
          </section>
        ) : (
          <section
            className="flex h-screen items-center justify-center lg:gap-x-12 2xl:gap-x-20"
            id="forgot-password-2"
          >
            <div id="left" className="lg:w-2/6 2xl:w-1/5">
              <img
                src="./images/Logo.svg"
                alt="site logo"
                className="mx-auto mb-32"
              />

              <h2 className="text-5xl font-semibold text-blue">
                Email has been sent
              </h2>
              <p id="p-tag-1" className="mb-28 mt-8 text-2xl text-fadedblack">
                Please check your inbox and click on the received link to reset
                password
              </p>

              <form onSubmit={handleSend}>
                <input type="hidden" name="email" value={email} />
                <p id="p-tag-2" className="text-center text-xl text-fadegrey">
                  Didn't receive a link?{" "}
                  <button
                    type="submit"
                    className="text-blue hover:font-semibold hover:underline"
                  >
                    Resend
                  </button>
                </p>
              </form>
            </div>

            <div id="right" className="lg:w-3/6 2xl:w-2/6">
              <img
                src="./images/forgot_password_2.svg"
                className="w-full"
                alt=""
              />
            </div>
          </section>
        )}
      </div>
    </>
  );
}

export default ForgotScreen;
