import { apiSlice } from "./apiSlice";
import { ITEMS_URL } from "../constants";

export const itemApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getItems: builder.query({
      query: () => ({
        url: `${ITEMS_URL}`,
      }),
      providesTags: ["Item"],
    }),
    addItem: builder.mutation({
      query: (data) => ({
        url: `${ITEMS_URL}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Item", "Notification"],
    }),
    updateItem: builder.mutation({
        query: ({ data, id }) => ({
          url: `${ITEMS_URL}/${id}`,
          method: "PUT",
          body: data,
        }),
        invalidatesTags: ["Item", "Notification"],
    }),
    deleteItem: builder.mutation({
        query: (id) => ({
          url: `${ITEMS_URL}/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Item", "Notification"],
    }),
  }),
});

export const {
  useGetItemsQuery,
  useAddItemMutation,
  useUpdateItemMutation,
  useDeleteItemMutation,
} = itemApiSlice;
