import { apiSlice } from "./apiSlice";
import { EMPLOYEES_URL } from "../constants";

export const employeeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query({
      query: () => ({
        url: `${EMPLOYEES_URL}`,
      }),
      providesTags: ["Employee"],
    }),
    addEmployee: builder.mutation({
      query: (data) => ({
        url: `${EMPLOYEES_URL}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Employee", "Store"],
    }),
    updateEmployee: builder.mutation({
      query: ({ data, id }) => ({
        url: `${EMPLOYEES_URL}/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Employee"],
    }),
    deleteEmployee: builder.mutation({
      query: (id) => ({
        url: `${EMPLOYEES_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Employee", "Store"],
    }),
    deleteEmployees: builder.mutation({
      query: (data) => ({
        url: `${EMPLOYEES_URL}`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Employee", "Store"],
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useAddEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useDeleteEmployeesMutation,
} = employeeApiSlice;
