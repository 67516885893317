import { Helmet } from "react-helmet-async";

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keywords} />
      <link
        rel="shortcut icon"
        href="images/favicon.ico"
        type="image/x-icon"
      />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "StockPilot - Login",
  description: "A place to host your business. Stay organized",
  keywords: "SME, Businesses, Business Inventory",
};

export default Meta;
