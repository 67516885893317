import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useLogoutMutation } from "../../slices/userApiSlice";
import { logout } from "../../slices/authSlice";

import "../Shared/css/SideBar.css";
import Logo from "../../images/Logo2.svg";
import UserManual from "../StockPilot_User_Manual.pdf";
import Loader from "../Loader";

import {
  AiFillHome,
  AiOutlineShoppingCart,
  AiFillMessage,
} from "react-icons/ai";
import { BiSolidHelpCircle } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { FaUsers } from "react-icons/fa";

const links = [
  {
    name: "Home",
    icon: <AiFillHome />,
    path: "/manager/home",
  },
  {
    name: "Employee",
    icon: <FaUsers />,
    path: "/manager/employees",
  },
  {
    name: "Items",
    icon: <AiOutlineShoppingCart />,
    path: "/manager/items",
  },
  {
    name: "Notifications",
    icon: <AiFillMessage />,
    path: "/manager/notifications",
  },
];

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userProfile } = useSelector((state) => state.auth);

  const [logOut, { isLoading: logoutLoader }] = useLogoutMutation();
  const handleLogout = async () => {
      dispatch(logout());
      navigate("/login");

      const res = await logOut({ id: userProfile.user_id }).unwrap();
      toast.success(res.message);
  };

  return (
    <div>
      {logoutLoader && <Loader />}
      <div className="manager-sidebar mx-2 flex h-full flex-col justify-center rounded-3xl bg-blue px-8 text-accentpurple lg:w-72 lg:gap-y-16 2xl:w-80 2xl:gap-y-28">
        <div className="logo flex flex-col items-center justify-center">
          <img src={Logo} alt="company logo" className="lg:w-48 2xl:w-56" />
          <h3 className="mt-2 text-xl">{userProfile.business_name}</h3>
          <hr className="border-1 mx-auto w-full border-accentpurple lg:mt-6 2xl:mt-10" />
        </div>

        <div className="links flex flex-col justify-center">
          {links.map((link) => (
            <NavLink
              to={link.path}
              className={
                "flex items-center gap-5 lg:mb-6 lg:pl-5 lg:text-lg 2xl:mb-8 2xl:pl-6 2xl:text-xl"
              }
            >
              {link.icon} {link.name}
            </NavLink>
          ))}

          <hr className="border-1 mx-auto w-full border-accentpurple lg:mt-0" />
        </div>

        <div className="bottom">
          <Link
            className="log-out flex items-center justify-center gap-2 hover:underline hover:underline-offset-4 lg:mb-3  lg:text-lg 2xl:mb-8 2xl:text-xl"
            to="#"
            onClick={handleLogout}
          >
            <FiLogOut />
            Log out
          </Link>

          <div className="help flex justify-center font-semibold hover:underline hover:underline-offset-4">
            <a
              href={UserManual}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-xl"
            >
              <BiSolidHelpCircle />
              Need Help
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
