import React from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";

function UserAvatar() {
  const { userProfile } = useSelector((state) => state.auth);

  return (
    <div>
      <div className="small-avatar hidden sm:block">
        <Avatar
          src={userProfile.photo}
          size="40"
          round={true}
          name={userProfile.first_name}
          maxInitials={1}
          textSizeRatio={1.8}
          color={"#090263"}
        />
      </div>
      <div className="big-avatar sm:hidden">
        <Avatar
          src={userProfile.photo}
          size="60"
          round={true}
          name={userProfile.first_name}
          maxInitials={1}
          textSizeRatio={1.8}
          color={"#090263"}
        />
      </div>
    </div>
  );
}

export default UserAvatar;
