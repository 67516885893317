import "../../../styles/css/Popup.css";

import { useUpdateNotificationMutation } from "../../../slices/notificationApiSlice";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import Loader from "../../Loader";

const FullMessage = (props) => {

  const [updateNotification, { isLoading: updateLoader }] = useUpdateNotificationMutation();

  const handleClick = async (event) => {
    if(props.activeNotification.statusmanager) {
      props.setShowPopup([false, null]);
      return;
    }
    props.setShowPopup([false, null]);
    
    try {
      const res = await updateNotification(props.activeNotification.notification_id).unwrap();
      toast.success(res.message);
    } catch (error) {
      toast.error(error?.data?.message || "Network Error", { toastId: 'E1' });
    }

  };

  return (
    <div className="popup-container">
      {/* {updateLoader && <Loader />} */}
      <section className="full-detail rounded-3xl bg-white p-12 lg:w-3/6 2xl:w-2/5">
        <div className="heading">
          <h2 className="font-semibold lg:text-3xl 2xl:text-4xl">
            {props.activeNotification.title}
          </h2>
          <p className="mb-1 mt-3 text-2xl">{props.activeNotification.subtitle}</p>
          <h3>{props.activeNotification.formatted_date}</h3>
        </div>

        <div className="body mt-8">
          <p>{props.activeNotification.description}</p>
        </div>

        <div className="mt-8 flex justify-end">
          <button
            className="flex cursor-pointer items-center justify-center gap-x-1 rounded-md bg-blue px-4 py-2 text-white hover:bg-lighterpurple"
            type="button"
            onClick={handleClick}
          >
            <CgClose /> Close
          </button>
        </div>
      </section>
    </div>
  );
};

export default FullMessage;
