// import "./DoneAlert.css";
import "../../styles/css/Popup.css";
import { MdOutlineDoneOutline } from "react-icons/md";
import { CgClose } from "react-icons/cg";

const DoneAlert = (props) => {
  return (
    <div className="popup-container">
      <div className="done z-50 flex flex-col items-center rounded-3xl bg-white px-[10rem] py-8">
        <MdOutlineDoneOutline className="text-[10rem]" />
        <h2 className="text-center text-3xl font-medium capitalize">
          {props.alertMessage[0]}
          <br />
          {props.alertMessage[1] ? props.alertMessage[1] : null}
        </h2>
        <button
          className="mt-10 flex cursor-pointer items-center gap-x-2 rounded-md bg-blue px-4 py-3 text-base text-white hover:bg-lighterpurple"
          type="button"
          onClick={() => props.alertState([false])}
        >
          <CgClose /> Close
        </button>
      </div>
    </div>
  );
};

export default DoneAlert;
