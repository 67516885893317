import SideBar from "./SideBar";
import Header from "./Employee/Header";
import Content from "./Employee/Content";
import Meta from "../Meta";
import Loader from "../Loader";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setEmployees, setStores } from "../../slices/authSlice";
import { toast } from "react-toastify";
import { useGetEmployeesQuery } from "../../slices/employeeApiSlice";
import { useGetStoresQuery } from "../../slices/storeApiSlice";

import "../Shared/css/Wrapper.css";
import "../Shared/css/ContentContainer.css";

const AdminEmployeeRoute = (props) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const {
    data: employees,
    isLoading: employeeLoader,
    error: employeeError,
    isFetching: employeeFetcher,
  } = useGetEmployeesQuery(null, {
    pollingInterval: 5  * 1000, // Refetch every 5 seconds
  });
  const {
    data: stores,
    isLoading: storeLoader,
    error: storeError,
  } = useGetStoresQuery(null, {
    pollingInterval: 5  * 1000, // Refetch every 5 seconds
  });

  useEffect(() => {
    if (employeeError || storeError) {
      toast.error(
        employeeError?.data?.message ||
          storeError?.data?.message ||
          "Network Error",
        { toastId: 'E1' }
      );
    }

    if (!employeeError && !employeeLoader) {
      dispatch(setEmployees(employees.employees));
    }
    if (!storeError && !storeLoader) {
      dispatch(setStores(stores.stores));
    }
  }, [
    dispatch,
    employees,
    stores,
    employeeError,
    storeError,
    employeeLoader,
    storeLoader,
  ]);

  return (
    <div className="wrapper">
      {employeeLoader && <Loader />}
      <Meta title={"StockPilot - Admin Employees"} />
      <SideBar />

      <div className="admin-content-container">
        <Header setSearch={setSearch} />
        {(!employeeError || !storeError) && (
          <Content
            search={search}
            loading={employeeLoader}
          />
        )}
      </div>
    </div>
  );
};

export default AdminEmployeeRoute;
