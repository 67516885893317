// import "./DeleteConfirmation.css";
import "../../styles/css/Popup.css";
import { AiFillWarning } from "react-icons/ai";

const DeleteConfirmation = (props) => {
  return (
    <div className="confirm-delete-container">
      <div className="delete flex flex-col items-center rounded-3xl bg-white px-40 py-8">
        <AiFillWarning className="text-[10rem] text-red-600" />
        <p className="text-center text-xl">
          {props.warning}
          <br />
          Are you sure you want to delete?
        </p>
        <div className="space-x-8">
          <button
            className="mt-10 cursor-pointer rounded-md bg-blue px-6 py-3 text-lg text-white hover:bg-lighterpurple"
            type="button"
            onClick={() => {
              props.confirm();
              props.popupState(false);
            }}
          >
            Yes
          </button>
          <button
            className="mt-10 cursor-pointer rounded-md bg-blue px-6 py-3 text-lg text-white hover:bg-lighterpurple"
            type="button"
            onClick={() => props.popupState(false)}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

DeleteConfirmation.defaultProps = {
  warning: "This cannot be undone",
};

export default DeleteConfirmation;
