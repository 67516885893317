import "../../Shared/css/MainContentContainer.css";

import ContentLoader from "react-content-loader";
import Cards from "./Cards";
import Trash from "./Trash";
import AddEmployee from "./AddEmployee";
import DoneAlert from "../../Alerts/DoneAlert";
import DeleteConfirmation from "../../Alerts/DeleteConfirmation";
import { useDeleteEmployeesMutation } from "../../../slices/employeeApiSlice";

import { useState } from "react";
import { TiPlus } from "react-icons/ti";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Loader from "../../Loader";

const Content = (props) => {
  const { employees } = useSelector((state) => state.auth);
  const [addEmployee, setAddEmployee] = useState(false);
  const [alert, setAlert] = useState([false]);
  const [selected, setSelected] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  const [deleteEmployees, { isLoading: deleteLoader }] =
    useDeleteEmployeesMutation();

  const deleteSelectedEmployees = async () => {
    try {
      const res = await deleteEmployees({ ids: selected }).unwrap();
      setAlert([true, [res.message]]);
      setSelected([]);
    } catch (error) {
      toast.error(error?.data?.message || "Network Error");
    }
  };

  const deleteMultipleEmployees = () => {
    deleteSelectedEmployees();
    setConfirmDelete(false);
    setDeletePopup(false);
  };

  // FUNCTION TO SEARCH
  let result = 0;
  if (props.search.length > 0) {
    let searchWord = props.search.toLowerCase();

    result = employees.filter(
      (employee) =>
        employee.first_name.toLowerCase().match(searchWord) ||
        employee.last_name.toLowerCase().match(searchWord) ||
        employee.username.toLowerCase().match(searchWord),
    );
  }

  return (
    <>
      {deleteLoader && <Loader />}
      <main className="main-content-container sm:rounded  sm:p-2 sm:shadow-md">
        <section className="top mb-4 flex items-center justify-between">
          {/* DISPLAY NUMBER OF EMPLOYEES */}
          <div className="left">
            {props.loading ? (
              <ContentLoader
                speed={1}
                interval={0.15}
                width={175} // Set the width to 80px
                height={55} // Set the height to 40px
                viewBox="0 0 175 55" // Adjust the viewBox accordingly
                backgroundColor="var(--accent-purple)" // Set the background color to #F5EDFF
                foregroundColor="var(--background-color)"
              >
                <rect x="0" y="0" rx="8" ry="8" width="175" height="55" />{" "}
                {/* Adjust border radius */}
              </ContentLoader>
            ) : (
              <p className="rounded-lg bg-accentpurple px-6 py-4 text-2xl font-semibold sm:p-2 sm:text-base">
                {result.length > 0
                  ? result.length === 1
                    ? result.length + " Employee"
                    : result.length + " Employees"
                  : employees.length === 1
                  ? employees.length + " Employee"
                  : employees.length + " Employees"}
              </p>
            )}
          </div>

          {/* ADD EMPLOYEE BUTTON */}
          <div className="right">
            <button
              className="add-employee flex cursor-pointer items-center gap-x-1 rounded-lg bg-blue px-4 py-3 text-xl text-white sm:p-2 sm:text-base"
              onClick={() => setAddEmployee(true)}
            >
              <TiPlus /> Add Employee
            </button>
          </div>
        </section>

        {selected.length > 1 ? (
          <Trash onClick={() => setDeletePopup(true)} />
        ) : null}

        <section className="bottom flex flex-wrap 2xl:gap-8 lg:gap-4">
          {result.length > 0 ? (
            result.map((employee) => (
              <Cards
                id={employee.user_id}
                info={employee}
                selected={selected}
                setSelected={setSelected}
              />
            ))
          ) : props.loading ? (
            <>
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
            </>
          ) : (
            employees.map((employee) => (
              <Cards
                id={employee.user_id}
                info={employee}
                selected={selected}
                setSelected={setSelected}
              />
            ))
          )}
        </section>
      </main>

      {addEmployee && (
        <AddEmployee
          employees={employees}
          formState={setAddEmployee}
          alertState={setAlert}
        />
      )}

      {alert[0] && <DoneAlert alertMessage={alert[1]} alertState={setAlert} />}

      {deletePopup && (
        <DeleteConfirmation
          popupState={setDeletePopup}
          confirm={deleteMultipleEmployees}
        />
      )}
    </>
  );
};

export default Content;
