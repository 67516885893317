import React from "react";

function SignupScreen() {
  return (
    <div>
      <h1 className="text-2xl text-blue">signup page in progress</h1>
    </div>
  );
}

export default SignupScreen;
