import "./ItemProfile.css";
import "../../../styles/css/Popup.css";
import SoapImage from "../../../images/SoapItem.svg";
import Loader from "../../Loader";

import { FaTrashAlt } from "react-icons/fa";
import { BsFillBookmarkFill } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { useState } from "react";
import { FiEdit } from "react-icons/fi";


import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDeleteItemMutation, useUpdateItemMutation } from "../../../slices/itemApiSlice";
import { convertISODateToYYYYMMDD } from "../../../utils/dates";
import { isEqual } from "lodash";

/// WORK ON EDITING ITEMS
/// ENSURE THE CASHIERS ORDER CAN BE CANCELLED

const ItemProfile = (props) => {
  const { categories } = useSelector((state) => state.auth);
  const [itemDetails, setItemDetails] = useState({
    ...props.detail,
    mfg_date: convertISODateToYYYYMMDD(props.detail.mfg_date),
    exp_date: convertISODateToYYYYMMDD(props.detail.exp_date),
    new_quantity: 0,
  });
  // const [edit, setEdit] = useState(false);

  const [isReadOnly, setReadOnly] = useState(true);
  const [updateItem, { isLoading: updateLoader }] = useUpdateItemMutation();
  const [deleteItem, { isLoading: deleteLoader }] = useDeleteItemMutation();
  // console.log(itemDetails);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setItemDetails((previousProps) => ({
      ...previousProps,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const originalItem = { ...props.detail, new_quantity: 0 }
    
    if(isEqual(originalItem, itemDetails)){
      toast.info("No Changes Made", { toastId: 'S1'});
      props.formState(false);
      return;
    }


    if(itemDetails.mfg_date > new Date()) {
      toast.error("Manufacturing date can't be greater than current date", { toastId: 'E3'})
      return;
    }

    if(itemDetails.exp_date < new Date()) {
      toast.error("Expiry date can't be less than current date", { toastId: 'E4'})
      return;
    }

    if(itemDetails.mfg_date > itemDetails.exp_date) {
      toast.error("Manufacturing date can't be greater than Expiry date", { toastId: 'E5'})
      return;
    }


    try {
      const res = await updateItem({
        data: itemDetails,
        id: itemDetails.store_item_id,
      }).unwrap();
      toast.success(res.message);
      props.deactivate(null);
      props.formState(false);
    } catch (error) {
      toast.error(error?.data?.message || "Network Error", { toastId: 'E1' });
    }

  };

  const handleDelete = async () => {
    try {
      const res = await deleteItem(itemDetails.store_item_id).unwrap();
      toast.success(res.message);
      props.deactivate(null);
      props.formState(false);
    } catch (error) {
      toast.error(error?.data?.message || "Network Error", { toastId: 'E1' });
    }
  }

  return (
    <div className="popup-container item-container">
      {(updateLoader || deleteLoader) && <Loader />}
      <section className="item rounded-3xl bg-white p-8">
        <img src={SoapImage} alt="item" className="mx-auto block" />

        <form>
          <div>
            <label for="name">Name</label>
            <br />
            <input
              type="text"
              id="name"
              name="name"
              value={itemDetails.name}
              onChange={handleInputChange}
              readOnly={isReadOnly}
              required
            />
            <br />
          </div>

          <div className="last-div">
            <div className="left-side">
              <label for="description">Description</label>
              <br />
              <input
                type="text"
                id="description"
                name="description"
                value={itemDetails.description}
                onChange={handleInputChange}
                readOnly={isReadOnly}
                required
              />
              <br />

              <label for="storeID">Category</label>
              <br />
              {isReadOnly ? (
                <input
                  type="text"
                  id="category"
                  name="category_name"
                  value={itemDetails.category_name}
                  readOnly={isReadOnly}
                  required
                />
              ) : (
                <select
                  id="category"
                  name="category_id"
                  value={itemDetails.category_id.value}
                  onChange={handleInputChange}
                  readOnly={isReadOnly}
                  required
                >
                  {categories.filter(category => category.name !== "All").map((category, index) => (
                    <option value={category.category_id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              )}
              <br />

              <label for="expirydate">Batch Expiry Date</label>
              <br />
              <input
                type="date"
                id="expirydate"
                name="exp_date"
                value={(itemDetails.exp_date)}
                onChange={handleInputChange}
                readOnly={isReadOnly}
                required
              />
              <br />

              <label for="costprice">Cost Price</label>
              <br />
              <input
                type="number"
                min="0.1"
                step="0.01"
                id="costprice"
                name="cost_price"
                value={itemDetails.cost_price}
                onChange={handleInputChange}
                readOnly={isReadOnly}
                required
              />
              <br />

              <div>
                {/* <button type="button"><FiEdit/> Edit</button> */}
                {isReadOnly ? (
                  <button
                    className="flex cursor-pointer items-center gap-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
                    type="button"
                    onClick={() => setReadOnly(false)}
                  >
                    <FiEdit /> Edit
                  </button>
                ) : (
                  <button
                    className="flex cursor-pointer items-center gap-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
                    type="button"
                    onClick={handleSubmit}
                  >
                    <BsFillBookmarkFill /> Save
                  </button>
                )}
              </div>
            </div>

            <div className="right-side">
              <label for="in-stock">
                {isReadOnly ? "In Stock" : "Quantity to Add/Remove"}
              </label>
              <br />
              <input
                type="number"
                id="in-stock"
                name={isReadOnly ? "stock_left" : "new_quantity"}
                value={isReadOnly ? itemDetails.stock_left : itemDetails.new_quantity}
                onChange={handleInputChange}
                readOnly={isReadOnly}
                required
              />
              <br />

              <label for="batch-number">Barcode.</label>
              <br />
              <input
                type="text"
                id="batch-number"
                name="barcode"
                value={itemDetails.barcode}
                onChange={handleInputChange}
                readOnly={isReadOnly}
              />
              <br />

              <label for="last-stocking-date">
                {isReadOnly ? "Last Stocking Date" : "Manufacturing Date"}
              </label>
              <br />
              {isReadOnly ? (
                <>
                  <input
                    type="date"
                    id="last-stocking-date"
                    name="stock_date"
                    value={convertISODateToYYYYMMDD(itemDetails.stock_date)}
                    readOnly
                    required
                  />
                  <br />
                </>
              ) : (
                <>
                  <input
                    type="date"
                    id="last-stocking-date"
                    name="mfg_date"
                    value={itemDetails.mfg_date}
                      readOnly={isReadOnly}
                      onChange={handleInputChange}
                    required
                  />
                  <br />
                </>
              )}

              <label for="selling-price">Selling Price</label>
              <br />
              <input
                type="number"
                min="0.1"
                step="0.1"
                id="selling-price"
                name="selling_price"
                value={itemDetails.selling_price}
                onChange={handleInputChange}
                readOnly={isReadOnly}
                required
              />
              <br />

              <div className="flex justify-between">
                <button
                  className="flex cursor-pointer items-center gap-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
                  type="button"
                  onClick={() => props.formState(false)}
                >
                  <CgClose /> Close
                </button>
                <button
                  className="flex cursor-pointer items-center gap-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
                  type="button"
                  onClick={handleDelete}
                >
                  <FaTrashAlt /> Delete
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default ItemProfile;
