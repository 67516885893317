import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { logout } from "./slices/authSlice";
import { useLogoutMutation } from "./slices/userApiSlice";

import "react-toastify/dist/ReactToastify.css";

function App() {
  const dispatch = useDispatch();
  const [logOut] = useLogoutMutation();

  useEffect(() => {
    const logoutUser = async (id) => {
      await logOut().unwrap();
    };

    const expirationTime = localStorage.getItem("expirationTime");
    const userProfile = localStorage.getItem("userProfile");

    if (expirationTime) {
      if (Date.now() > expirationTime) {
        dispatch(logout());
        logoutUser(userProfile?.user_id);
      }
    }
  }, [dispatch, logOut]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      />
      <Outlet />
    </>
  );
}

export default App;
