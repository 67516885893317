import "./NewCustomer.css";
import "../../styles/css/Popup.css";
import Loader from "../Loader";

import { useState } from "react";
import { toast } from "react-toastify";
import { useAddCustomerMutation } from "../../slices/customerApiSlice";

const NewCustomer = (props) => {
  const [newCustomerDetails, setNewCustomerDetails] = useState({
    first_name: "",
    other_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    gender: "female",
  });

  const [addCustomer, { isLoading: addLoader }] = useAddCustomerMutation();

  const handleChange = (event) => {
    const { name, value } = event.target;

    setNewCustomerDetails((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const res = await addCustomer(newCustomerDetails).unwrap();
      toast.success(res.message);
      // props.refetch();
      props.setNewCustomerPopup(false); 
    } catch (error) {
      toast.error(error?.data?.message || error?.error);
    }
  };
  return (
    <div className="popup-container">
    {addLoader && <Loader />}
      <div className="new-customer rounded-2xl bg-white p-8">
        <h2 className="mb-8 text-center font-semibold">New Customer</h2>

        <form onSubmit={handleSubmit} className="px-8">
          <label htmlFor="first-name">First Name</label>
          <br />
          <input
            type="text"
            name="first_name"
            id="first-name"
            onChange={handleChange}
            value={newCustomerDetails.first_name}
            required
          />
          <br />

          <label htmlFor="other-name">Other Name</label>
          <br />
          <input
            type="text"
            name="other_name"
            id="other-name"
            onChange={handleChange}
            value={newCustomerDetails.other_name}
          />
          <br />

          <label htmlFor="last-name">Last Name</label>
          <br />
          <input
            type="text"
            name="last_name"
            id="last-name"
            onChange={handleChange}
            value={newCustomerDetails.last_name}
          />
          <br />

          <label htmlFor="customer-email">Email</label>
          <br />
          <input
            type="email"
            name="email"
            id="customer-email"
            onChange={handleChange}
            value={newCustomerDetails.email}
          />
          <br />

          <label htmlFor="customer-tel">Phone Number</label>
          <br />
          <input
            type="tel"
            name="phone_number"
            id="customer-tel"
            placeholder="+1234567890"
            pattern="^\+?\d{10,}$"
            onChange={handleChange}
            value={newCustomerDetails.phone_number}
            required
          />
          <br />

          <label htmlFor="customer-gender">Gender</label>
          <br />
          <select
            id="customer-gender"
            name="gender"
            onChange={handleChange}
            value={newCustomerDetails.gender.value}
            required
          >
            <option value="female">Female</option>
            <option value="male">Male</option>
          </select>
          <br />

          <div className="mt-4 space-x-8">
            <button
              className="cursor-pointer rounded-lg bg-blue p-4 text-sm font-semibold text-white hover:bg-lighterpurple"
              type="submit"
            >
              ADD
            </button>
            <button
              className="cursor-pointer rounded-lg bg-blue p-4 text-sm font-semibold text-white hover:bg-lighterpurple"
              type="button"
              onClick={() => props.setNewCustomerPopup(false)}
            >
              CLOSE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewCustomer;
