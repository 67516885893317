import "./EmailReceipt.css";
import "../../../styles/css/Popup.css";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCartItems, setPaymentDetails } from "../../../slices/cartSlice";
import { toast } from "react-toastify";
import { useAddSaleMutation } from "../../../slices/saleApiSlice";
import Loader from "../../Loader";

const EmailReceipt = (props) => {
  const { paymentDetails, cartItems, itemsPrice, itemsOriginalPrice,taxPrice, totalPrice } = useSelector(state => state.cart);

  const { userProfile } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [addSale, { isLoading: addLoader }] = useAddSaleMutation();
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setCustomer((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const sendEmail = async (event) => {
    event.preventDefault();

    const receiptData = {
      paymentDetails,
      itemsPrice,
      itemsOriginalPrice,
      taxPrice,
      cartItems,
      totalPrice,
      businessName: userProfile.business_name,
      customer
    }

    try {
      const res = await addSale(receiptData).unwrap();
      toast.success(res.message);
      
      dispatch(clearCartItems());
      props.setPaymentMenu(false);
    } catch (error) {
      toast.error(error?.data?.message || error?.error);
    }
  };

  return (
    <div className="popup-container">
      {!addLoader && <Loader />}
      <div className="email-receipt rounded-3xl bg-white p-8 lg:w-2/6 2xl:w-1/5">
        <h2 className="mb-8 text-center font-semibold">
          Send Receipt Via Email
        </h2>
        <form onSubmit={sendEmail} className="w-full">
          <label htmlFor="customer-name">Customer's Name</label>
          <br />
          <input
            type="text"
            name="name"
            id="customer-name"
            value={customer.name}
            onChange={handleChange}
            required
          />
          <br />

            <label htmlFor="customer-email">Customer's Email Address</label>
            <br />
            <input
              type="email"
              name="email"
              id="customer-email"
              value={customer.email}
              onChange={handleChange}
              required
            />
            <br />

          <div className="flex justify-end gap-6">
            <button
              className="flex cursor-pointer items-center gap-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
              type="submit"
            >
              SEND
            </button>
            <button
              className="flex cursor-pointer items-center gap-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
              type="button"
              onClick={() => props.setEmailReceiptPopup(false)}
            >
              DISCARD
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailReceipt;
