import SideBar from "./SideBar";
import Header from "./Items/Header";
import Content from "./Items/Content";
import Payment from "./Items/Payment";
import BackHeader from "./Items/BackHeader";
import Meta from "../Meta";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCategories, setItems } from "../../slices/authSlice";
import { toast } from "react-toastify";

import { useGetCategoriesQuery } from "../../slices/categoryApiSlice";
import { useGetItemsQuery } from "../../slices/itemApiSlice";

import "../Shared/css/Wrapper.css";
import "../Shared/css/ContentContainer.css";

const CashierItemsRoute = () => {
  const [paymentMenu, setPaymentMenu] = useState(false);

  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const {
    data: categories,
    isLoading: categoryLoader,
    error: categoryError
  } = useGetCategoriesQuery();

  const {
    data: items,
    isLoading: itemLoader,
    error: itemError
  } = useGetItemsQuery(null, {
    pollingInterval: 2000
  });

  

  useEffect(() => {
    if (categoryError || itemError) {
      console.log(categoryError);
      console.log(itemError)
      toast.error(
          categoryError?.data?.message ||
          "Network Error",
      {toastId: 'E1'});
      toast.error(
        itemError?.data?.message ||
        "Network Error",
      {toastId: 'E1'});
    }

    if (!categoryError && !categoryLoader) {
      dispatch(setCategories([{ name: "All" }, ...categories.categories]));
    }
    if (!itemError && !itemLoader) {
      dispatch(setItems(items.items));
    }
  }, [
    dispatch,
    categories,
    categoryError,
    categoryLoader,
    items,
    itemError,
    itemLoader
  ]);

  return (
    <div className="wrapper">

      <Meta title={"StockPilot - Cashier Items"} />
      <SideBar />
      <div className="cashier-content-container">
        {paymentMenu ? (
          <BackHeader
            setPaymentMenu={setPaymentMenu}
          />
        ) : (
          <Header setSearch={setSearch}/>
        )}
        {paymentMenu ? (
          <Payment
            setPaymentMenu={setPaymentMenu}
          />
        ) : (
          (!categoryError || !itemError) && 
          <Content
            setPaymentMenu={setPaymentMenu}
            search={search} 
            loading={categoryLoader || itemLoader} 
          />
        )} 
      </div>
    </div>
  );
};

export default CashierItemsRoute;
