import "./AddComponent.css";
import "../../../styles/css/Popup.css";

import ProductImage from "../../../images/image-gallery.svg";
// import CustomWebcam from '../../CustomWebcam';

import { CgClose } from "react-icons/cg";
import { BsFillBookmarkFill } from "react-icons/bs";
import { useState } from "react";
// import {FaBarcode} from 'react-icons/fa';

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAddCategoryMutation } from "../../../slices/categoryApiSlice";
import Loader from "../../Loader";
import { useAddItemMutation } from "../../../slices/itemApiSlice";

const AddComponent = (props) => {
  const { categories } = useSelector((state) => state.auth);

  const [productDetails, setProductDetails] = useState({
    name: "",
    description: "",
    selling_price: 0,
    cost_price: 0,
    barcode: "",
    category_id: categories[1]?.category_id,
    exp_date: "",
    mfg_date: "",
    stock_quantity: 0,
  });

  const [newCategory, setNewCategory] = useState({
    name: "",
  });

  const [addCategory, {isLoading: addCategoryLoader}] = useAddCategoryMutation();
  const [addItem, { isLoading: addItemLoader }] = useAddItemMutation();
  // const [webcam, setWebcam] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setProductDetails((previousProps) => ({
      ...previousProps,
      [name]: value,
    }));
  };

  const handleCategoryChange = (event) => {
    const { name, value } = event.target;

    setNewCategory((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const categorySubmit = async (event) => {
    event.preventDefault();

    try {
      const res = await addCategory(newCategory).unwrap();
      toast.success(res.message);
      // props.categoryRefetch();
      props.formState(false);
    } catch (error) {
      toast.error(error?.data?.message || "Network Error");
    }
  };

  const productSubmit = async (event) => {
    event.preventDefault();

    if(productDetails.mfg_date > new Date()) {
      toast.error("Manufacturing date can't be greater than current date", { toastId: 'E3'})
      return;
    }

    if(productDetails.exp_date < new Date()) {
      toast.error("Expiry date can't be less than current date", { toastId: 'E4'})
      return;
    }

    if(productDetails.mfg_date > productDetails.exp_date) {
      toast.error("Manufacturing date can't be greater than Expiry date", { toastId: 'E5'})
      return;
    }

    try {
      const res = await addItem(productDetails).unwrap();
      toast.success(res.message);
      // props.itemRefetch();
      props.formState(false);
    } catch (error) {
      toast.error(error?.data?.message || "Network Error");
    }

  };

  return (
    <>
    <div className="popup-container add-component">
      {/* {props.add === 'category' ? console.log('category') : null}
            {props.add === 'prodcut' ? console.log('product') : null} */}
      { (addCategoryLoader) && <Loader />}
      {props.add === "category" ? (
        // NEW CATEGORY POPUP
        <form
          className="new-category rounded-3xl bg-white p-8"
          onSubmit={categorySubmit}
        >
          <label className="text-xl text-fadegrey" htmlFor="name">
            Category Name
          </label>
          <br />
          <input
            className="mt-2 w-72 rounded-lg bg-accentpurple p-2 font-semibold text-blue outline-none"
            type="text"
            name="name"
            id="name"
            onChange={handleCategoryChange}
            value={newCategory.name}
            required
          />
          <br />

          {/* <label htmlFor="description">Description</label><br/> */}
          {/* <input type="text" name="description" id="description" /><br/> */}
          {/* <textarea name="description" id="description" cols="60" rows="4" onChange={handleCategoryChange} value={newCategory.description} required></textarea> */}

          <div className="mt-12 flex gap-8">
            <button
              className="flex cursor-pointer items-center gap-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
              type="submit"
            >
              <BsFillBookmarkFill /> Save
            </button>
            <button
              className="flex cursor-pointer items-center gap-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
              type="button"
              onClick={() => props.formState(false)}
            >
              <CgClose /> Close
            </button>
          </div>
        </form>
      ) : null}

      { addItemLoader && <Loader /> }
      {props.add === "product" ? (
        // NEW PRODUCT POPUP
        <section className="new-product rounded-3xl bg-white p-8">
          <h3 className="mb-4 text-center font-semibold">New Product</h3>
          <div className="mx-auto flex h-32 w-32 items-center justify-center rounded-full border-2 border-blue">
            <img src={ProductImage} alt="new product" />
          </div>

          <form onSubmit={productSubmit}>
            <div className="flex gap-8">
              <div className="left">
                <label for="name">Name</label>
                <br />
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={productDetails.name}
                  onChange={handleInputChange}
                  required
                />
                <br />

                <label for="description">Description</label>
                <br />
                <input
                  type="text"
                  id="last-name"
                  name="description"
                  value={productDetails.description}
                  onChange={handleInputChange}
                  required
                />
                <br />

                <label for="category">Category</label>
                <br />
                <select
                  id="category"
                  name="category_id"
                  value={productDetails.category_id.value}
                  onChange={handleInputChange}
                  required
                >
                  {categories.filter(category => category.name !== "All").map((category, index) => (
                    <option value={category.category_id}>{category.name}</option>
                  ))}
                </select>
                <br />

                <label for="mfg_date">Manufacturing Date</label>
                <br />
                <input
                  type="date"
                  id="mfg_date"
                  name="mfg_date"
                  value={productDetails.mfg_date}
                  onChange={handleInputChange}
                  required
                />
                <br />

                <label for="expiry_date">Expiry Date</label>
                <br />
                <input
                  type="date"
                  id="expiry_date"
                  name="exp_date"
                  value={productDetails.exp_date}
                  onChange={handleInputChange}
                  required
                />
                <br />

                {/* <div className='barcode'>
                                    <FaBarcode onClick={() => setWebcam(true)}/>
                                </div> */}
              </div>

              <div className="right">
                {/* FIX CATEGORIES FOR THIS PART */}
                <label for="batch_no">Barcode</label>
                <br />
                <input
                  type="text"
                  id="batch_no"
                  name="barcode"
                  value={productDetails.barcode}
                  onChange={handleInputChange}
                />
                <br />

                <label for="quantity">In Stock</label>
                <br />
                <input
                  type="text"
                  id="quantity"
                  name="stock_quantity"
                  value={productDetails.stock_quantity}
                  onChange={handleInputChange}
                  min={1}
                  required
                />
                <br />

                <label for="cost_price">Cost Price (GH¢)</label>
                <br />
                <input
                  type="text"
                  id="cost_price"
                  name="cost_price"
                  value={productDetails.cost_price}
                  onChange={handleInputChange}min={0.1}
                  step={0.01}
                  required
                />
                <br />

                <label for="selling_price">Selling Price (GH¢)</label>
                <br />
                <input
                  type="text"
                  id="selling_price"
                  name="selling_price"
                  value={productDetails.selling_price}
                  onChange={handleInputChange}
                  step={0.1}
                  min={0.1}
                  required
                />

                <div className="mt-6 flex gap-6">
                  <button
                    className="flex cursor-pointer items-center gap-x-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
                    type="submit"
                  >
                    <BsFillBookmarkFill /> Save
                  </button>
                  <button
                    className="flex cursor-pointer items-center gap-x-3 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
                    type="button"
                    onClick={() => props.formState(false)}
                  >
                    <CgClose /> Close
                  </button>
                </div>
              </div>
            </div>
          </form>
        </section>
        ) : null}
        {
          // webcam ? <CustomWebcam setWebcam={setWebcam} /> : null
        }
        
      </div>
    </>
  );
};

export default AddComponent;
