import UserAvatar from "../../Shared/UserAvatar";
import HeaderSearch from "../../Shared/HeaderSearch";

const Header = (props) => {
  return (
    <div className="">
      <nav className="flex items-center justify-between rounded-2xl bg-white py-2 lg:px-4 2xl:px-8">
        <HeaderSearch
              placeholder="Search items"
              setSearch={props.setSearch}
            />

        <div className="user-avatar">
          <UserAvatar />
        </div>
      </nav>
    </div>
  );
};

export default Header;
