import "../../Shared/css/MainContentContainer.css";
import Cards from "./Cards";

import { useSelector } from "react-redux";
import { useState } from "react";
import ContentLoader from "react-content-loader";

const Content = (props) => {
  const { employees } = useSelector((state) => state.auth);
  const [selected, setSelected] = useState([]);

  // FUNCTION TO SEARCH
  let result = 0;
  if (props.search.length > 0) {
    let searchWord = props.search.toLowerCase();

    result = employees.filter(
      (employee) =>
        employee.first_name.toLowerCase().match(searchWord) ||
        employee.last_name.toLowerCase().match(searchWord) ||
        employee.username.toLowerCase().match(searchWord),
    );
  }

  return (
    <>
      <main className="main-content-container">
        <section className="top mb-4 flex items-center justify-between">
          <div className="left">
            {props.loading ? (
              <ContentLoader
                speed={1}
                interval={0.15}
                width={175} // Set the width to 80px
                height={55} // Set the height to 40px
                viewBox="0 0 175 55" // Adjust the viewBox accordingly
                backgroundColor="var(--accent-purple)" // Set the background color to #F5EDFF
                foregroundColor="var(--background-color)"
              >
                <rect x="0" y="0" rx="8" ry="8" width="175" height="55" />{" "}
                {/* Adjust border radius */}
              </ContentLoader>
            ) : (
              <p className="rounded-2xl bg-accentpurple px-6 py-4 text-2xl font-semibold">
                {result.length > 0
                  ? result.length === 1
                    ? result.length + " Employee"
                    : result.length + " Employees"
                  : employees.length === 1
                  ? employees.length + " Employee"
                  : employees.length + " Employees"}
              </p>
            )}
          </div>
        </section>

        <section className="bottom flex flex-wrap lg:gap-4 2xl:gap-8">
          {result.length > 0 ? (
            result.map((employee, index) => (
              <Cards
                id={employee.user_id}
                info={employee}
                selected={selected}
                setSelected={setSelected}
              />
            ))
          ) : props.loading ? (
            <>
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
            </>
          ) : (
            employees.map((employee, index) => (
              <Cards
                id={employee.user_id}
                info={employee}
                selected={selected}
                setSelected={setSelected}
              />
            ))
          )}
        </section>
      </main>
    </>
  );
};

export default Content;
