import "./AddStore.css";
import "../../../styles/css/Popup.css";

import { CgClose } from "react-icons/cg";
import { BsFillBookmarkFill } from "react-icons/bs";
import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useAddStoreMutation } from "../../../slices/storeApiSlice";
import { toast } from "react-toastify";

import Loader from "../../Loader";

const AddStore = (props) => {
  const { userProfile } = useSelector((state) => state.auth);

  const [storeDetails, setStoreDetails] = useState({
    name: "",
    location: "",
    contact_info: "",
    business_id: userProfile.business_id,
  });

  const [addStore, { isLoading: addLoader }] = useAddStoreMutation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setStoreDetails((previousProps) => ({
      ...previousProps,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const res = await addStore(storeDetails).unwrap();
      props.alertState([true, [res.message]]);
      props.formState(false);
    } catch (error) {
      toast.error(error?.data?.message || "Network Error");
    }
  };

  return (
    <div className="popup-container">
      {addLoader && <Loader />}
      <section className="new-store rounded-3xl bg-white p-8">
        <h2 className="mx-auto mb-2 flex h-28 w-28 items-center justify-center rounded-full bg-blue text-7xl font-semibold text-white">
          ?
        </h2>
        <h3 className="mb-8 text-center text-2xl font-semibold">New Store</h3>

        <form onSubmit={handleSubmit} className="flex justify-center gap-x-8">
          <div className="left">
            <label for="first-name">Store Name</label>
            <br />
            <input
              type="text"
              id="store-name"
              name="name"
              value={storeDetails.name}
              onChange={handleInputChange}
              required
            />
            <br />

            <label for="tel">Contact</label>
            <br />
            <input
              type="tel"
              id="contact-info"
              name="contact_info"
              value={storeDetails.contact_info}
              onChange={handleInputChange}
              required
            />
            <br />
          </div>

          <div className="right">
            <label for="location">Location</label>
            <br />
            <input
              type="text"
              id="other-name"
              name="location"
              value={storeDetails.location}
              onChange={handleInputChange}
            />
            <br />

            <div className="mt-4 flex justify-between">
              <button
                className="flex cursor-pointer items-center gap-x-2 rounded-xl bg-blue p-3 text-xl text-white hover:bg-lighterpurple"
                type="submit"
              >
                <BsFillBookmarkFill /> Save
              </button>
              <button
                className="flex cursor-pointer items-center gap-x-2 rounded-xl bg-blue p-3 text-xl text-white hover:bg-lighterpurple"
                type="button"
                onClick={() => props.formState(false)}
              >
                <CgClose /> Close
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default AddStore;
