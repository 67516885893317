import { useEffect, useState } from "react";
import { TiPlus } from "react-icons/ti";

import "./Content.css";

import Loader from "../../Loader";
import OrderDetails from "./OrderDetails";
import NewCustomer from "../NewCustomer";

import ContentLoader from "react-content-loader";
import { useSelector, useDispatch } from "react-redux";
import { capitalize } from "lodash";
import { addToCart } from "../../../slices/cartSlice";
// HANDLE ITEM IMAGE PATH ISSUE

const Content = (props) => {
  const { items, categories } = useSelector((state) => state.auth);
  const { cartItems } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  // const [selected, setSelected] = useState([false, null]);
  // const [selectedProducts, setSelectedProducts] = useState([]);
  const [newCustomerPopup, setNewCustomerPopup] = useState(false);
  const [activeCategory, setActiveCategory] = useState({name: "All"})
  const [activeItems, setActiveItems] = useState(null);

   // FUNCTION TO SEARCH
   let result = 0;
   if (props.search.length > 0) {
     let searchWord = props.search.toLowerCase();
 
     result = items.filter(item => item.stock_left > 0).filter((item) =>
       item.name.toLowerCase().match(searchWord) ||
       item.description.toLowerCase().match(searchWord) ||
       item.stock_left.toString().match(searchWord) ||
       item.selling_price.toString().match(searchWord)
     );
   }

  const addToCartHandler = (item, qty) => {
    dispatch(addToCart({ ...item, qty, new: true }));
  }
   
  return (
    <>
      <main className="main-content-container cashier-items-section cashier-bg-color flex flex-col lg:gap-y-4 2xl:gap-y-8">
        <section className="top">
          <div className="heading mb-4 flex items-center justify-between">
            <div className="left flex items-center gap-5">
              <h2 className="text-3xl font-semibold">Categories</h2>
              <p className="rounded-lg bg-blue px-3 py-2 font-semibold text-white">
                {props.search.length ? result.length : items.length} items
              </p>
            </div>

            <div className="right">
              <button
                className="flex cursor-pointer items-center gap-1 rounded-lg bg-blue p-3 text-white hover:bg-lighterpurple"
                type="button"
                onClick={() => setNewCustomerPopup(true)}
              >
                <TiPlus /> Add Customer
              </button>
            </div>
          </div>

          <div className="categories flex gap-4 rounded-3xl bg-white px-8 py-2">
              { props.loading ?
                <ContentLoader
                  speed={1}
                  interval={0.15}
                  width={1400} // Set the width to 80px
                  height={40} // Set the height to 40px
                  viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                  backgroundColor="var(--accent-purple)" // Set the background color to purple
                  foregroundColor="var(--background-color)"
                >
                  <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                  {/* Adjust border radius */}
                </ContentLoader>
                :
                categories.map((category, index) => (
                  <p
                    className={`${category?.name === activeCategory?.name ? "active" : null} cursor-pointer p-2 text-xl font-semibold`}
                    onClick={() => setActiveCategory(category)}
                    id={index}
                  >
                    {capitalize(category?.name)}
                  </p>
                ))
              }
          </div>
        </section>
        <section className="bottom flex h-full justify-between gap-x-8 overflow-scroll">
          <section className="left flex h-full w-full flex-col gap-y-2">
            <div className="heading mx-8 flex text-2xl font-semibold">
              <div className="w-3/5">
                <p>Product</p>
              </div>
              <div className="flex w-2/5 justify-between">
                <p>Price</p>
                <p>In Stock</p>
              </div>
            </div>

            <section className="overflow-y-scroll">
              { result.length > 0 ?
                  result.map((item, index) => (
                    <div
                    id={index}
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-white bg-white px-8 py-2 hover:border-blue"
                    onClick={() => addToCartHandler(item, 1)}
                    >
                      <div className="product w-3/5 pr-4">
                        <h2 className="truncate font-semibold">{item?.name}</h2>
                        <p className="truncate text-fadegrey">{item?.description ? item?.description : "-"}</p>
                      </div>

                      <div className="info flex w-2/5 justify-between font-semibold">
                        <p>GH¢ {item?.selling_price}</p>
                        <p>{item?.stock_left}</p>
                      </div>
                    </div> 
                  ))
                :
               props.loading ?
                  <>
                  <div
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-white bg-white px-8 py-2 hover:border-blue"
                  >
                    <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                      {/* Adjust border radius */}
                    </ContentLoader>
                  </div> 
                  <div
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-white bg-white px-8 py-2 hover:border-blue"
                  >
                    <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                      {/* Adjust border radius */}
                    </ContentLoader>
                  </div> 
                  <div
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-white bg-white px-8 py-2 hover:border-blue"
                  >
                    <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                      {/* Adjust border radius */}
                    </ContentLoader>
                  </div> 
                  <div
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-white bg-white px-8 py-2 hover:border-blue"
                  >
                    <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                      {/* Adjust border radius */}
                    </ContentLoader>
                  </div> 
                  <div
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-white bg-white px-8 py-2 hover:border-blue"
                  >
                    <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                      {/* Adjust border radius */}
                    </ContentLoader>
                  </div> 
                  <div
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-white bg-white px-8 py-2 hover:border-blue"
                  >
                    <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                      {/* Adjust border radius */}
                    </ContentLoader>
                  </div> 
                  </>
                :
              activeCategory?.name === "All" ? 
                items.filter(item => item.stock_left > 0).map((item, index) => (
                  <div
                    id={index}
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-white bg-white px-8 py-2 hover:border-blue"
                    onClick={() => addToCartHandler(item, 1)}
                  >
                      <div className="product w-3/5 pr-4">
                        <h2 className="truncate font-semibold">{item?.name}</h2>
                        <p className="truncate text-fadegrey">{item?.description ? item?.description : "-"}</p>
                      </div>

                      <div className="info flex w-2/5 justify-between font-semibold">
                        <p>GH¢ {item?.selling_price}</p>
                        <p>{item?.stock_left}</p>
                      </div>
                  </div> 
                )) 
                :
                items.filter(item => item.stock_left > 0).filter(item => item?.category_id === activeCategory?.category_id).map((item, index) => (
                  <div
                    id={index}
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-white bg-white px-8 py-2 hover:border-blue"
                    onClick={() => addToCartHandler(item, 1)}
                  >
                    <div className="product w-3/5 pr-4">
                      <h2 className="truncate font-semibold">{item?.name}</h2>
                      <p className="truncate text-fadegrey">{item?.description ? item?.description : "-"}</p>
                    </div>

                    <div className="info flex w-2/5 justify-between font-semibold">
                      <p>GH¢ {item?.selling_price}</p>
                      <p>{item?.stock_left}</p>
                    </div>
                  </div> 
                )) 
              }
            </section>
          </section>
          <section className="right flex flex-col gap-y-2 overflow-scroll lg:w-3/6 2xl:w-2/6">
            <p className="text-center text-2xl font-semibold">Order Details</p>
            <OrderDetails
              setPaymentMenu={props.setPaymentMenu}
            />
          </section>
        </section>
      </main>

      {newCustomerPopup ? (
        <NewCustomer
          setNewCustomerPopup={setNewCustomerPopup}
          // setChanged={setChanged}
        />
      ) : null}
    </>
  );
};

export default Content;
