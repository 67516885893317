import "../styles/css/Popup.css";

const Loader = () => {
  return (
    <div className="popup-container">
      <div className="loader fixed z-50 h-32 w-32 animate-spin rounded-full border-[16px] border-accentpurple border-t-blue"></div>
    </div>
  );
};

export default Loader;
