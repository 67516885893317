import { apiSlice } from "./apiSlice";
import { CUSTOMERS_URL } from "../constants";

export const customersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: () => ({
        url: `${CUSTOMERS_URL}`,
      }),
      providesTags: ["Customer"],
    }),
    addCustomer: builder.mutation({
      query: (data) => ({
        url: `${CUSTOMERS_URL}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Customer"],
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useAddCustomerMutation
} = customersApiSlice;
