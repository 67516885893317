const NotFound = () => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <h3 className="mb-9 font-mono text-4xl">404</h3>
      <h2 className="font-serif text-8xl font-bold uppercase">
        Page Not Found
      </h2>
    </div>
  );
};

export default NotFound;
