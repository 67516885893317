import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import "./index.css";
import App from "./App";

// ADMIN COMPONENTS
import AdminHomeRoute from "./components/Admin/AdminHomeRoute";
import AdminEmployeeRoute from "./components/Admin/AdminEmployeeRoute";
import AdminStoreRoute from "./components/Admin/AdminStoreRoute";
import AdminNotificationsRoute from "./components/Admin/AdminNotificationsRoute";
import AdminRoute from "./routes/AdminRoute";

// CASHIER COMPONENTS
import CashierItemsRoute from "./components/Cashier/CashierItemsRoute";
import CashierCustomerRoute from "./components/Cashier/CashierCustomerRoute";
import CashierRoute from "./routes/CashierRoute";
// import ConnectionsRoute from './components/Cashier/ConnectionsRoute';

//MANAGER COMPONENTS
import ManagerHomeRoute from "./components/Manager/ManagerHomeRoute";
import ManagerEmployeeRoute from "./components/Manager/ManagerEmployeeRoute";
import ManagerItemsRoute from "./components/Manager/ManagerItemsRoute";
import ManagerNotificationsRoute from "./components/Manager/ManagerNotificationsRoute";
import ManagerRoute from "./routes/ManagerRoute";
import PrivateRoute from "./routes/PrivateRoute";

// OTHER COMPONENTS
import NotFound from "./components/NotFound";
import LoginScreen from "./screens/LoginScreen";
import ForgotScreen from "./screens/ForgotScreen";
import ResetScreen from "./screens/ResetScreen";
import SignupScreen from "./screens/SignupScreen";
import LandingPage from "./screens/LandingPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<NotFound />}>
      <Route path="/landing-page" element={<LandingPage />} />
      <Route path="/signup" element={<SignupScreen />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/forgot" element={<ForgotScreen />} />
      <Route path="/:type/password/:token" element={<ResetScreen />} />

      <Route index={true} path="/" element={<PrivateRoute />} />

      {/* For Admins */}
      <Route path="" element={<AdminRoute />}>
        <Route index={true} path="/admin/home" element={<AdminHomeRoute />} />
        <Route path="/admin/employees" element={<AdminEmployeeRoute />} />
        <Route path="/admin/stores" element={<AdminStoreRoute />} />
        <Route
          path="/admin/notifications"
          element={<AdminNotificationsRoute />}
        />
      </Route>

      {/* For Cashiers */}
      <Route path="" element={<CashierRoute />}>
        <Route
          index={true}
          path="/cashier/home"
          element={<CashierItemsRoute />}
        />
        <Route path="/cashier/customers" element={<CashierCustomerRoute />} />
      </Route>

      {/* For Managers */}
      <Route path="" element={<ManagerRoute />}>
        <Route
          index={true}
          path="/manager/home"
          element={<ManagerHomeRoute />}
        />
        <Route path="/manager/employees" element={<ManagerEmployeeRoute />} />
        <Route path="/manager/items" element={<ManagerItemsRoute />} />
        <Route
          path="/manager/notifications"
          element={<ManagerNotificationsRoute />}
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <HelmetProvider>
    <Provider store={store}>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </Provider>
  </HelmetProvider>,
);
