import "./AddEmployee.css";
import "../../../styles/css/Popup.css";

import { CgClose } from "react-icons/cg";
import { BsFillBookmarkFill } from "react-icons/bs";
import { useState } from "react";
import Loader from "../../Loader";

import { useSelector } from "react-redux";
import { useAddEmployeeMutation } from "../../../slices/employeeApiSlice";
import { toast } from "react-toastify";

const AddEmployee = (props) => {
  const { stores, userProfile } = useSelector((state) => state.auth);

  if (stores.length === 0) {
    toast.info("No Stores Available", { autoClose: 6000 });
  }

  const [employeeDetails, setEmployeeDetails] = useState({
    first_name: "",
    other_name: "",
    last_name: "",
    role: "cashier",
    phone_number: "",
    address: "",
    email: "",
    store_id: stores[0]?.store_id,
    salary: 0,
    business_id: userProfile.business_id,
  });

  const [addEmployee, { isLoading: addLoader }] = useAddEmployeeMutation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Switch the store id when there is a change in role
    if (name === "role") {
      setEmployeeDetails((previousProps) => ({
        ...previousProps,
        store_id:
          value === "manager"
            ? stores.filter((store) => !store.manager.user_id)[0].store_id
            : stores[0]?.store_id,
        [name]: value,
      }));
    } else {
      setEmployeeDetails((previousProps) => ({
        ...previousProps,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const res = await addEmployee(employeeDetails).unwrap();
      props.formState(false);
      props.alertState([true, ["Successfully Added User", res.message]]);
    } catch (error) {
      toast.error(error?.data?.message || "Network Error");
    }
  };

  return (
    <div className="popup-container">
      {addLoader && <Loader />}
      <section className="new-employee rounded-3xl bg-white px-8 pb-6 pt-8">
        <h2 className="mx-auto flex h-28 w-28 items-center justify-center rounded-full bg-blue text-7xl font-semibold text-white">
          ?
        </h2>
        <h3 className="mb-8 mt-4 text-center text-2xl font-semibold">
          New Employee
        </h3>

        <form
          onSubmit={handleSubmit}
          className="flex justify-center gap-x-8 sm:flex sm:flex-col sm:max-h-[400px] sm:overflow-scroll"
        >
          <div className="left">
            <label for="first-name">First Name</label>
            <br />
            <input
              type="text"
              id="first-name"
              name="first_name"
              onChange={handleInputChange}
              required
            />
            <br />

            <label for="other-name">Other Name</label>
            <br />
            <input
              type="text"
              id="other-name"
              name="other_name"
              onChange={handleInputChange}
            />
            <br />

            <label for="last-name">Last Name</label>
            <br />
            <input
              type="text"
              id="last-name"
              name="last_name"
              onChange={handleInputChange}
              required
            />
            <br />

            <label for="store-name">Store Name</label>
            <br />
            <select
              id="store-name"
              name="store_id"
              value={employeeDetails.store_id.value}
              onChange={handleInputChange}
              required
            >
              {employeeDetails.role === "cashier"
                ? stores.map((store) => (
                    <option value={store.store_id}>{store.name}</option>
                  ))
                : stores
                    .filter((store) => !store.manager.user_id)
                    .map((store) => (
                      <option value={store.store_id}>{store.name}</option>
                    ))}
            </select>
            <br />

            <label for="email">Email</label>
            <br />
            <input
              type="email"
              id="email"
              name="email"
              onChange={handleInputChange}
              required
            />
            <br />
          </div>

          <div className="right">
            <label for="role">Position </label>
            <br />
            <select
              id="role"
              name="role"
              value={employeeDetails.role.value}
              onChange={handleInputChange}
              required
            >
              {stores.length > 0 && <option value="cashier">Cashier</option>}
              {stores.filter((store) => !store.manager.user_id).length > 0 && (
                <option value="manager">Manager</option>
              )}
            </select>
            <br />

            <label for="phone-number">Mobile Number</label>
            <br />
            <input
              type="tel"
              id="phone-number"
              name="phone_number"
              onChange={handleInputChange}
              required
            />
            <br />

            <label for="address">Digital Address</label>
            <br />
            <input
              type="text"
              id="address"
              name="address"
              onChange={handleInputChange}
              required
            />
            <br />

            <label for="salary">Salary</label>
            <br />
            <input
              type="number"
              id="salary"
              min="0"
              name="salary"
              value={employeeDetails.salary}
              onChange={handleInputChange}
              required
            />

            <div className="mt-6 flex justify-between">
              <button
                type="submit"
                className="flex cursor-pointer items-center gap-x-3 rounded-lg bg-blue px-4 py-3 text-xl text-white hover:bg-lighterpurple"
              >
                <BsFillBookmarkFill /> Save
              </button>
              <button
                type="button"
                className="flex cursor-pointer items-center gap-x-3 rounded-lg bg-blue px-4 py-3 text-xl text-white hover:bg-lighterpurple"
                onClick={() => props.formState(false)}
              >
                <CgClose /> Close
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default AddEmployee;
