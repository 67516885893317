import "./StoreProfile.css";
import "../../../styles/css/Popup.css";

import Loader from "../../Loader";
import DeleteConfirmation from "../../Alerts/DeleteConfirmation";

import { FaTrashAlt } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { FiEdit } from "react-icons/fi";
import { BsFillBookmarkFill } from "react-icons/bs";
import { useState } from "react";
import { useUpdateStoreMutation } from "../../../slices/storeApiSlice";
import { toast } from "react-toastify";

const StoreProfile = (props) => {
  const [storeDetails, setStoreDetails] = useState({
    ...props.info,
  });

  const [isReadOnly, setReadOnly] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  const [updateStore, { isLoading: updateLoader }] = useUpdateStoreMutation();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setStoreDetails((previousProps) => ({
      ...previousProps,
      [name]: value,
    }));
  };

  // POPUP TO CONFIRM STORE DELETION
  const deleteAction = () => {
    props.delete(storeDetails.store_id);
    setConfirmDelete(false);
    setDeletePopup(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await updateStore({
        data: storeDetails,
        id: storeDetails.store_id,
      }).unwrap();
      props.alertState([true, [res.message]]);
      props.formState(false);
      setReadOnly(true);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.message || "Network Error");
    }
  };

  return (
    <div className="popup-container">
      <section className="admin-store-profile rounded-3xl bg-white p-8">
        <h2 className="mx-auto mb-4 flex items-center justify-center rounded-full bg-blue font-semibold text-white lg:h-24 lg:w-24 lg:text-5xl 2xl:h-32 2xl:w-32 2xl:text-7xl">
          {storeDetails.name[0]}
        </h2>

        <form className="flex w-full justify-center gap-x-8">
          <div className="left">
            <label for="store_name">Store Name</label>
            <br />
            <input
              type="text"
              id="store-name"
              name="name"
              value={storeDetails.name}
              onChange={handleInputChange}
              readOnly={isReadOnly}
            />
            <br />

            <label for="location">Store Location</label>
            <br />
            <input
              type="text"
              id="location"
              name="location"
              value={storeDetails.location}
              onChange={handleInputChange}
              readOnly={isReadOnly}
              required
            />
            <br />

            <label for="contact_info">Store Contact</label>
            <br />
            <input
              type="tel"
              id="contact-info"
              name="contact_info"
              value={storeDetails.contact_info}
              onChange={handleInputChange}
              readOnly={isReadOnly}
              required
            />
            <br />
          </div>

          <div className="right">
            <label for="store_id">Store ID</label>
            <br />
            <input
              type="text"
              id="store-id"
              name="store_id"
              value={storeDetails.store_id}
              onChange={handleInputChange}
              readOnly
            />
            <br />

            <label for="manager_name">Manager</label>
            <br />
            <input
              type="text"
              id="manager-name"
              name="manager_name"
              value={
                storeDetails.manager.user_id
                  ? storeDetails.manager.first_name +
                    " " +
                    (storeDetails.manager.other_name
                      ? storeDetails.manager.other_name
                      : "") +
                    " " +
                    storeDetails.manager.last_name
                  : "N/A"
              }
              onChange={handleInputChange}
              readOnly
            />
            <br />

            <label for="employees_count">Number of Employees</label>
            <br />
            <input
              type="text"
              id="employees-count"
              name="employees-count"
              value={storeDetails.cashiers.length}
              onChange={handleInputChange}
              readOnly
              required
            />
            <br />
          </div>
        </form>

        <div className="center">
          <label for="employees">List Of Employees</label>
          <br />
          <div className="heading mb-4 mt-2 grid grid-cols-2 gap-x-8 bg-accentpurple py-2">
            <p className="w-72">Name</p>
            <p className="w-72">Email</p>
          </div>

          <div className="overflow-y-scroll lg:max-h-24 2xl:max-h-36">
            {storeDetails.cashiers.map((cashier) => (
              <div className="cashier my-2 grid grid-cols-2 gap-x-8 bg-accentpurple px-0 py-2">
                <p className="w-72 overflow-hidden text-ellipsis whitespace-nowrap">
                  {cashier.first_name} {cashier.last_name}
                </p>
                <p className="w-72 overflow-hidden text-ellipsis whitespace-nowrap">
                  {cashier.email}
                </p>
              </div>
            ))}
          </div>
          <div className="three mt-6 flex justify-between">
            {isReadOnly ? (
              <button
                className="flex cursor-pointer items-center gap-x-2 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
                type="button"
                onClick={() => setReadOnly(false)}
              >
                <FiEdit /> Edit
              </button>
            ) : (
              <button
                className="flex cursor-pointer items-center gap-x-2 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
                type="button"
                onClick={handleSubmit}
              >
                <BsFillBookmarkFill /> Save
              </button>
            )}
            <button
              className="flex cursor-pointer items-center gap-x-2 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
              type="button"
              onClick={() => props.formState(false)}
            >
              <CgClose /> Close
            </button>
            <button
              className="flex cursor-pointer items-center gap-x-2 rounded-xl bg-blue p-3 text-white hover:bg-lighterpurple"
              type="button"
              onClick={() => setDeletePopup(true)}
            >
              <FaTrashAlt /> Delete
            </button>
          </div>
        </div>
      </section>

      {updateLoader && <Loader />}
      {deletePopup ? (
        <DeleteConfirmation
          warning={
            "This will delete all Manager and Cashiers associated with the store"
          }
          popupState={setDeletePopup}
          confirm={deleteAction}
        />
      ) : null}
    </div>
  );
};

export default StoreProfile;

/* 

DETAILS YOU CAN EDIT

Under that, you can change the:
Location
Name
Store Contact
Manager

*/
