import { useState } from "react";
import Loader from "../../Loader";

import "./Payment.css";
import "../../Shared/css/MainContentContainer.css";
// import "./PaymentRight.css";
import EmailReceipt from "./EmailReceipt";

import Receipt from "./Receipt";
import { useDispatch, useSelector } from "react-redux";
import { clearCartItems, setPaymentDetails } from "../../../slices/cartSlice";
import { toast } from "react-toastify";
import { useAddSaleMutation } from "../../../slices/saleApiSlice";

const Payment = (props) => {
  const {
    paymentDetails,
    itemsPrice,
    itemsOriginalPrice,
    cartItems,
    taxPrice,
    totalPrice,
  } = useSelector((state) => state.cart);
  const { userProfile } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [emailReceiptPopup, setEmailReceiptPopup] = useState(false);

  const [addSale, { isLoading: addLoader }] = useAddSaleMutation();

  const handleGenerateReceiptPDF = async () => {
    const receiptData = {
      paymentDetails,
      itemsPrice,
      itemsOriginalPrice,
      taxPrice,
      cartItems,
      totalPrice,
      businessName: userProfile.business_name,
    };

    try {
      const res = await addSale(receiptData).unwrap();
      toast.success(res.message);

      dispatch(clearCartItems());
      props.setPaymentMenu(false);
      // Convert base64 to binary
      const binaryData = atob(res.pdf);

      // Create a Blob
      const blob = new Blob(
        [new Uint8Array([...binaryData].map((char) => char.charCodeAt(0)))],
        { type: "application/pdf" },
      );

      // Create a data URL
      const dataUrl = URL.createObjectURL(blob);
      
      const newTab = window.open(dataUrl, "_blank");
      setTimeout(() => {
        if (newTab) newTab.print();
      }, 1000);
    } catch (error) {
      toast.error(error?.data?.message || error?.error);
    }
  };

  const handlePaymentMethod = (method) => {
    dispatch(setPaymentDetails({ ...paymentDetails, method }));
  };

  const handlePaymentDetails = (amount) => {
    dispatch(
      setPaymentDetails({
        ...paymentDetails,
        amount,
        balance: isNaN((amount - totalPrice).toFixed(2))
          ? -totalPrice
          : (amount - totalPrice).toFixed(2),
      }),
    );
  };

  return (
    <>
      {addLoader && <Loader />}
      <main className="main-content-container payment-main-section cashier-bg-color">
        <section className="bottom flex h-full justify-between 2xl:gap-x-20 lg:gap-x-8">
          <section className="left flex w-full flex-col overflow-scroll">
            <div className="heading flex justify-between px-8 pb-2 text-2xl font-semibold">
              <p>PAYMENT OPTIONS</p>
            </div>

            <section className="flex h-full flex-col justify-between rounded-3xl bg-white px-8 py-4">
              <div className="calculations">
                <div>
                  <label htmlFor="total_amount">TOTAL AMOUNT (GH¢)</label>
                  <br />
                  <input
                    type="text"
                    name="total_amount"
                    id="total_amount"
                    value={totalPrice}
                    readOnly={true}
                  />
                </div>
                <div>
                  <div>
                    <label htmlFor="amount_received">CASH RECEIVED (GH¢)</label>
                    <br />
                    <input
                      type="number"
                      name="amount_received"
                      min={0}
                      onChange={(e) =>
                        handlePaymentDetails(parseFloat(e.target.value))
                      }
                      step={0.1}
                      // onChange={handleChange}
                      id="amount_received"
                      value={paymentDetails.amount}
                    />
                  </div>
                  <div>
                    <label htmlFor="balance">CHANGE (GH¢)</label>
                    <br />
                    <input
                      type="text"
                      name="balance"
                      id="balance"
                      value={paymentDetails.balance}
                      readOnly={true}
                    />
                    <br />
                  </div>
                </div>
              </div>

              <div className="payment-option flex 2xl:gap-x-12 lg:gap-x-4">
                <label>
                  <input
                    type="radio"
                    checked={paymentDetails.method === "Mobile Money"}
                    id="mobile-money"
                    onChange={(e) => handlePaymentMethod(e.target.value)}
                    value="Mobile Money"
                  />
                  Mobile Money
                </label>

                <label>
                  <input
                    type="radio"
                    checked={paymentDetails.method === "Bank Transfer"}
                    id="bank-transfer"
                    onChange={(e) => handlePaymentMethod(e.target.value)}
                    value="Bank Transfer"
                  />
                  Bank Transfer
                </label>

                <label>
                  <input
                    type="radio"
                    checked={paymentDetails.method === "Card"}
                    id="card"
                    onChange={(e) => handlePaymentMethod(e.target.value)}
                    value="Card"
                  />
                  Credit/Debit Card
                </label>

                <label>
                  <input
                    type="radio"
                    id="cash"
                    checked={paymentDetails.method === "Cash"}
                    onChange={(e) => handlePaymentMethod(e.target.value)}
                    value="Cash"
                  />
                  Cash
                </label>
              </div>

              <div className="receipt flex gap-x-8">
                <button
                  type="button"
                  onClick={() => handleGenerateReceiptPDF()}
                  disabled={paymentDetails.balance >= 0 ? false : true}
                  className="rounded-md bg-blue p-3 text-white hover:bg-lighterpurple"
                >
                  PRINT RECEIPT
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setEmailReceiptPopup(true);
                  }}
                  disabled={paymentDetails.balance >= 0 ? false : true}
                  className="rounded-md bg-blue p-3 text-white hover:bg-lighterpurple"
                >
                  SEND RECEIPT VIA EMAIL
                </button>
              </div>
            </section>
          </section>

          <section className="right flex h-full flex-col 2xl:w-2/6 lg:w-2/5">
            <p className="mb-2 ml-6 text-2xl font-semibold">ORDER DETAILS</p>

            <div className="payment-order-details flex h-full flex-col justify-between gap-y-4 overflow-y-scroll rounded-2xl bg-white p-4">
              <section className="ordered-products h-full space-y-2 overflow-y-scroll">
                {cartItems.map((item, index) => (
                  <div className="product mb-3 rounded-xl bg-accentpurple p-3">
                    <div className="summary flex w-full items-center justify-between gap-x-2 font-medium">
                      <div className="w-full truncate">
                        <p className="truncate">{item.name}</p>
                      </div>

                      <div className="flex w-36 items-center justify-between gap-2 font-medium">
                        <p>GH¢ {item.selling_price}</p>
                      </div>
                    </div>
                    <div className="more-info flex justify-between 2xl:mt-3 lg:mt-1">
                      <div>
                        <label className="font-mediun" htmlFor="quantity">
                          Quantity
                        </label>
                        {/* <br /> */}
                        <input
                          className="ml-4 w-2/5 p-1 outline-none"
                          type="text"
                          name="quantity"
                          value={item.qty}
                          id="quantity"
                          readOnly
                        />
                      </div>

                      {/* <div>
                          <label htmlFor="discount">Discount(%)</label><br/>
                          <input type="text" name="discount" id="discount" />
                        </div> */}
                    </div>
                  </div>
                ))}
              </section>

              <section className="total-amount">
                <div className="rounded bg-accentpurple px-4 py-3 font-semibold">
                  <p className="flex justify-between">
                    <span>Sub total</span> GGH¢ {itemsPrice}
                  </p>
                  <p className="flex justify-between 2xl:my-1 lg:my-0">
                    <span>Tax</span> GH¢ {taxPrice}
                  </p>
                  <p className="flex justify-between">
                    <span>Payable amount</span> GH¢ {totalPrice}
                  </p>
                </div>
              </section>
            </div>
          </section>
        </section>
      </main>

      {emailReceiptPopup ? (
        <EmailReceipt
          setPaymentMenu={props.setPaymentMenu}
          setEmailReceiptPopup={setEmailReceiptPopup}
        />
      ) : null}
      {/* <EmailReceipt setEmailReceiptPopup={setEmailReceiptPopup}/> */}
    </>
  );
};

export default Payment;

/*

TASKS TO WORK ON


*/
