import { useState, useEffect } from "react";
import { TiPlus } from "react-icons/ti";

import NewCustomer from "../NewCustomer";
import Loader from "../../Loader";

import { useSelector } from "react-redux";
import { useAddCustomerMutation } from "../../../slices/customerApiSlice";

// HANDLE ITEM IMAGE PATH ISSUE

const Content = (props) => {
  const { customers } = useSelector(state => state.auth);
  const [newCustomerPopup, setNewCustomerPopup] = useState(false);
  let result = 0;
  if (props.search.length > 0) {
    let searchWord = props.search.toLowerCase();

    result = customers.filter((customer) =>
      customer.first_name.toLowerCase().match(searchWord) ||
      customer.last_name.toLowerCase().match(searchWord) ||
      customer.other_name.toLowerCase().match(searchWord) ||
      customer.email.toLowerCase().match(searchWord) ||
      customer.phone_number.toLowerCase().match(searchWord) 
    );
  }

  return (
    <>
      {props.loading && <Loader />}
      <main className="cashier-bg-color main-content-container">
        <section className="heading flex customers-center justify-between">
          <div className="left">
            <p className="text-2xl font-semibold">Customer List</p>
          </div>

          <div className="right">
            <button
              className="flex cursor-pointer customers-center gap-x-2 rounded-lg bg-blue p-2 text-xl text-white"
              type="button"
              onClick={() => setNewCustomerPopup(true)}
            >
              <TiPlus /> Add Customer
            </button>
          </div>
        </section>

        <section className="bottom mt-8 font-semibold">
          <div className="column-names mb-4 flex gap-x-4 px-8">
            <p className="w-1/4">NAME</p>
            <p className="w-1/4 text-center">EMAIL</p>
            <p className="w-1/4 text-center">PHONE NUMBER</p>
            <p className="w-1/4 text-right">GENDER</p>
          </div>

          <section className="customers">
            {result.length > 0 ?
              result.map((customer, index) => (
              <div
                id={index}
                className="customer my-2 flex gap-x-4 rounded-xl bg-white px-8 py-4"
              >
                <p className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap capitalize">
                  {customer.first_name} {customer.last_name && customer.last_name}
                </p>
                <p className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap text-center">
                  {customer.email ? customer.email : "N/A"}
                </p>
                <p className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap text-center">
                  {customer.phone_number}
                </p>
                <p className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap text-right capitalize">
                  {customer.gender}
                </p>
              </div>
            ))
              :
              customers.map((customer, index) => (
              <div
                id={index}
                className="customer my-2 flex gap-x-4 rounded-xl bg-white px-8 py-4"
              >
                <p className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap capitalize">
                  {customer.first_name} {customer.last_name && customer.last_name}
                </p>
                <p className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap text-center">
                  {customer.email ? customer.email : "N/A"}
                </p>
                <p className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap text-center">
                  {customer.phone_number}
                </p>
                <p className="w-1/4 overflow-hidden text-ellipsis whitespace-nowrap text-right capitalize">
                  {customer.gender}
                </p>
              </div>
            ))}
          </section>
        </section>
      </main>

      {newCustomerPopup ? (
        <NewCustomer
          setNewCustomerPopup={setNewCustomerPopup}
        />
      ) : null}
    </>
  );
};

export default Content;

/*

TASKS TO WORK ON


*/
