import { apiSlice } from "./apiSlice";
import { STORES_URL } from "../constants";

export const storeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStores: builder.query({
      query: () => ({
        url: `${STORES_URL}`,
      }),
      providesTags: ["Store"],
    }),
    addStore: builder.mutation({
      query: (data) => ({
        url: `${STORES_URL}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Store"],
    }),
    updateStore: builder.mutation({
      query: ({ data, id }) => ({
        url: `${STORES_URL}/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Store"],
    }),
    deleteStore: builder.mutation({
      query: (id) => ({
        url: `${STORES_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Store", "Employee"],
    }),
  }),
});

export const {
  useGetStoresQuery,
  useAddStoreMutation,
  useUpdateStoreMutation,
  useDeleteStoreMutation,
} = storeApiSlice;
