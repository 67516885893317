import { useState } from "react";
import { FiEdit } from "react-icons/fi";

import "./ItemDetails.css";
import ItemProfile from "./ItemProfile";
import pics from "../../../images/SoapItem.svg";
import { convertISODateToMMMDDYYYY } from "../../../utils/dates";

const ItemDetails = ({ item, alertState, deactivate }) => {
  const [editItem, setEditItem] = useState(false);
  // const { data: item, isLoading, error } = useGetItemQuery(props.item._id);

  return (
    <div> 
      <div className="description rounded-3xl bg-accentpurple">
        <div className="item-selected overflow-y-scroll p-4">
          <div className="flex justify-end">
            <h4
              onClick={() => setEditItem(true)}
              className="flex cursor-pointer items-center gap-x-1 text-fadegrey hover:text-lighterpurple"
            >
              Edit <FiEdit />
            </h4>
          </div>
          <img
            src={pics}
            alt="selected item"
            className="relative mx-auto -mt-6 mb-6 block w-36"
          />
          <section className="details">
            <div>
              <p>name</p>
              <p>{item.name}</p>
            </div>
            <div>
              <p>description</p>
              <p>{item.description ? item.description : "-"}</p>
            </div>
            <div>
              <p>category</p>
              <p>{item.category_name}</p>
            </div>
            <div>
              <p>barcode</p>
              <p>{item.barcode}</p>
            </div>
            <div>
              <p>batch expiry date</p>
              <p>{convertISODateToMMMDDYYYY(item.exp_date)}</p>
            </div>
            <div>
              <p>last stocking date</p>
              <p>{convertISODateToMMMDDYYYY(item.stock_date)}</p>
            </div>
            <div>
              <p>in stock</p>
              <p>{item.stock_left} remaining</p>
            </div>
            <div>
              <p>selling price</p>
              <p>GH¢ {item.selling_price}</p>
            </div>
            <div>
              <p>cost price</p>
              <p>GH¢ {item.cost_price}</p>
            </div>
          </section>
        </div>
      </div>

     

      {editItem ? (
        <ItemProfile
          detail={item}
          formState={setEditItem}
          alertState={alertState}
          deactivate={deactivate}
        />
      ) : null}
    </div>
  );
};

export default ItemDetails;
