import ContentLoader from "react-content-loader";
import StoreProfile from "./StoreProfile";
import DoneAlert from "../../Alerts/DoneAlert";
import Loader from "../../Loader";

import { GrUserManager } from "react-icons/gr";
import { HiUser } from "react-icons/hi";
import { MdCall } from "react-icons/md";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDeleteStoreMutation } from "../../../slices/storeApiSlice";

const MAX_LENGTH = {
  nameLength: 18,
  managerLength: 20,
};

const Cards = (props) => {
  const [storeProfile, setStoreProfile] = useState(false);
  const [alert, setAlert] = useState([false]);

  const [deleteStore, { isLoading: deleteLoader }] = useDeleteStoreMutation();

  const handleDelete = async (id) => {
    try {
      const res = await deleteStore(id).unwrap();
      toast.info(res.message);
      setAlert([true, [res.message]]);
      setStoreProfile(false);
    } catch (error) {
      toast.error(error?.data?.message || error?.error);
    }
  };

  return props.loading ? (
    <section className="">
      <ContentLoader
        speed={1}
        interval={0.15}
        width={290} // Set the width to 290px
        height={310} // Set the height to 310px
        viewBox="0 0 290 310" // Adjust the viewBox accordingly
        backgroundColor="var(--accent-purple)" // Set the background color to #F5EDFF
        foregroundColor="var(--background-color)"
      >
        <rect x="0" y="0" rx="16" ry="16" width="290" height="310" />{" "}
        {/* Adjust border radius */}
      </ContentLoader>
    </section>
  ) : (
    <section className="rounded-2xl bg-accentpurple p-2 hover:shadow-xl lg:w-80 2xl:w-[21.7rem]">
      <section
        id={props.id}
        className="flex cursor-pointer flex-col items-center rounded-2xl bg-accentpurple p-2 pt-8"
        onClick={() => {
          setStoreProfile(true);
        }}
      >
        <div className="flex flex-col items-center">
          <p className="initial mb-1 flex h-20 w-20 items-center justify-center rounded-full bg-blue text-5xl font-semibold text-white">
            {props.info.name[0]}
          </p>
          <p className="name text-2xl font-semibold capitalize">
            {props.info.name.length > MAX_LENGTH.nameLength
              ? props.info.name.slice(0, MAX_LENGTH.nameLength) + " ..."
              : props.info.name}
          </p>
          <hr className="border-1 mx-auto mb-8 mt-4 w-full border-blue" />
        </div>

        <div className="flex w-full justify-between gap-x-4 text-center">
          <div className="div1 w-full rounded-lg bg-white p-2">
            <h2>Store Contact</h2>
            <p className="text-fadedblack">{props.info.contact_info}</p>
          </div>

          <div className="div2 w-full rounded-lg bg-white p-2">
            <h2>Store ID</h2>
            <p className="text-fadedblack">{props.info.store_id}</p>
          </div>
        </div>

        <div className="mt-4 w-full rounded-xl bg-white p-4 text-left">
          <p className="mb-3 flex items-center gap-x-2">
            <HiUser /> Manager:{" "}
            {props.info.manager.user_id
              ? (
                  props.info.manager.first_name +
                  " " +
                  props.info.manager.last_name
                ).length > MAX_LENGTH.managerLength
                ? (
                    props.info.manager.first_name +
                    " " +
                    props.info.manager.last_name
                  ).slice(0, MAX_LENGTH.managerLength) + " ..."
                : props.info.manager.first_name +
                  " " +
                  props.info.manager.last_name
              : "N/A"}
          </p>
          <p className="flex items-center gap-x-2">
            <MdCall />{" "}
            {props.info.manager.user_id
              ? props.info.manager.phone_number
              : "N/A"}
          </p>
        </div>
      </section>

      {storeProfile ? (
        <StoreProfile
          id={props.id}
          delete={handleDelete}
          formState={setStoreProfile}
          info={props.info}
          alertState={setAlert}
        />
      ) : null}

      {alert[0] ? (
        <DoneAlert alertMessage={alert[1]} alertState={setAlert} />
      ) : null}

      {deleteLoader && <Loader />}
    </section>
  );
};

export default Cards;
