import { useState } from "react";

import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
// import "./Content.css";
// import "../MainSection.css";
import Cards from "./Cards";
import AddStore from "./AddStore";
import DoneAlert from "../../Alerts/DoneAlert";
import Loader from "../../Loader";

import { TiPlus } from "react-icons/ti";

import "../../Shared/css/MainContentContainer.css";

const Content = (props) => {
  const { stores } = useSelector((state) => state.auth);
  const [addStore, setAddStore] = useState(false);
  const [selected, setSelected] = useState([]);
  const [alert, setAlert] = useState([false]);

  // FUNCTION TO SEARCH
  let result = 0;
  if (props.search.length > 0) {
    let searchWord = props.search.toLowerCase();

    result = stores.filter((store) =>
      store.name.toLowerCase().match(searchWord),
    );
  }

  return (
    <>
      <main className="main-content-container">
        <section className="top mb-4 flex items-center justify-between">
          <div className="left ">
            {props.loading ? (
              <ContentLoader
                speed={1}
                interval={0.15}
                width={175} // Set the width to 80px
                height={55} // Set the height to 40px
                viewBox="0 0 175 55" // Adjust the viewBox accordingly
                backgroundColor="var(--accent-purple)" // Set the background color to purple
                foregroundColor="var(--background-color)"
              >
                <rect x="0" y="0" rx="8" ry="8" width="175" height="55" />{" "}
                {/* Adjust border radius */}
              </ContentLoader>
            ) : (
              <p className="rounded-lg  bg-accentpurple px-6 py-4 text-2xl font-semibold sm:p-2 sm:text-base">
                {result.length > 0
                  ? result.length === 1
                    ? result.length + " Store"
                    : result.length + " Stores"
                  : stores.length === 1
                  ? stores.length + " Store"
                  : stores.length + " Stores"}
              </p>
            )}
          </div>

          <div className="right">
            <button
              className="add-store  flex cursor-pointer items-center gap-x-1 rounded-lg bg-blue px-4 py-3 text-xl text-white sm:p-2 sm:text-base"
              onClick={() => setAddStore(true)}
            >
              <TiPlus /> Add Store
            </button>
          </div>
        </section>

        <section className="bottom flex flex-wrap 2xl:gap-8 lg:gap-4">
          {result.length > 0 ? (
            result.map((store, index) => (
              <Cards
                id={store.store_id}
                info={store}
                selected={selected}
                setSelected={setSelected}
              />
            ))
          ) : props.loading ? (
            <>
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
              <Cards loading={props.loading} />
            </>
          ) : (
            stores.map((store, index) => (
              <Cards
                id={store.store_id}
                info={store}
                selected={selected}
                setSelected={setSelected}
              />
            ))
          )}
        </section>
      </main>

      {addStore ? (
        <AddStore
          stores={stores}
          formState={setAddStore}
          alertState={setAlert}
        />
      ) : null}

      {alert[0] ? (
        <DoneAlert alertMessage={alert[1]} alertState={setAlert} />
      ) : null}
    </>
  );
};

export default Content;
