import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    topStats: {},
    reports: {
        customerStats: [],
        salesStats: [],
        revenueStats: [],
    },
    recentSales: [],
    topSelling: []
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setTop: (state, action) => {
      state.topStats = action.payload;
      return state;
    },
    setReports: (state, action) => {
      state.reports = action.payload;
      return state;
    },
    setRecentSales: (state, action) => {
      state.recentSales = action.payload;
      return state;
    },
    setTopSelling: (state, action) => {
      state.topSelling = action.payload;
      return state;
    },
  },
});

export const { setRecentSales, setReports, setTop, setTopSelling } = homeSlice.actions;

export default homeSlice.reducer;
