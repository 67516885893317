import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

function HeaderSearch(props) {
  return (
    <div class="search-bar flex items-center overflow-hidden rounded-[2.5rem] bg-accentpurple px-[1rem] py-[0.8px] text-[2rem] text-fadegrey sm:px-1 sm:py-0">
      <AiOutlineSearch />
      <input
        type="search"
        id="search-box"
        name="search"
        className="w-full border-0 bg-accentpurple py-2 text-xl outline-none"
        placeholder={props.placeholder}
        onChange={(event) => props.setSearch(event.target.value)}
      />
    </div>
  );
}

export default HeaderSearch;
