import "./CustomWebcam.css";
// import Webcam from "react-webcam";

import { RiArrowGoBackLine } from "react-icons/ri";
import { TbCapture } from "react-icons/tb";
import { useCallback, useRef, useState } from "react";
// import Tesseract from "tesseract.js";

const CustomWebcam = (props) => {
  // const webcamRef = useRef(null);
  // const [capturedImage, setCapturedImage] = useState(null);
  // const [barcode, setBarCode] = useState("");

  // function converttotxt(imageData) {
  //   Tesseract.recognize(imageData).then((response) =>
  //     console.log(response.data.text),
  //   );
  //   // console.log(imgText.data.text);
  // }

  // const capture = useCallback(() => {
  //   const capturedImage = webcamRef.current.getScreenshot();
  //   setCapturedImage(capturedImage);
  //   // console.log(capturedImage);
  //   // setBarCode(imgText);
  //   // props.setWebcam(false)
  // }, [webcamRef]);

  // return (
  //   <div>
  //     <div className="webcam-container">
  //       <Webcam
  //         audio={false}
  //         height={400}
  //         width={400}
  //         ref={webcamRef}
  //         screenshotFormat="image/jpeg"
  //       />

  //       <div>
  //         <TbCapture onClick={() => capture()} />
  //         <RiArrowGoBackLine onClick={() => props.setWebcam(false)} />
  //       </div>
  //     </div>

  //     <div>{capturedImage ? converttotxt(capturedImage) : null}</div>
  //   </div>
  // );
};

export default CustomWebcam;
