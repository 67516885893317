import "./Content.scoped.css";
import ApexCharts from "apexcharts";
import * as echarts from "echarts";
import { useEffect, useState } from "react";
// import { Aleart } from "../../Alert";
import "./HomeUi.css";
import Loader from "../../Loader";
import { useSelector } from "react-redux";

const Content = () => {
  const { topStats, topSelling, recentSales, reports } = useSelector(state => state.home);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var budgetChart = echarts
          .init(document.querySelector("#budgetChart"))
          .setOption({
            legend: {
              data: ["Allocated Budget", "Actual Spending"],
            },
            radar: {
              // shape: 'circle',
              indicator: [
                {
                  name: "Sales",
                  max: 6500,
                },
                {
                  name: "Administration",
                  max: 16000,
                },
                {
                  name: "Information Technology",
                  max: 30000,
                },
                {
                  name: "Customer Support",
                  max: 38000,
                },
                {
                  name: "Development",
                  max: 52000,
                },
                {
                  name: "Marketing",
                  max: 25000,
                },
              ],
            },
            series: [
              {
                name: "Budget vs spending",
                type: "radar",
                data: [
                  {
                    value: [4200, 3000, 20000, 35000, 50000, 18000],
                    name: "Allocated Budget",
                  },
                  {
                    value: [5000, 14000, 28000, 26000, 42000, 21000],
                    name: "Actual Spending",
                  },
                ],
              },
            ],
          });

        // Calculate the date strings for the last five months
        const dateStrings = [];
        const today = new Date();
        for (let i = 4; i >= 0; i--) {
          const currentDate = new Date(
            today.getFullYear(),
            today.getMonth() - i,
            1,
          );
          const formattedDate = currentDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
          });
          dateStrings.push(formattedDate);
        }

        // Create the ApexCharts instance with the dynamically generated x-axis labels
        new ApexCharts(document.querySelector("#reportsChart"), {
          series: [
            {
              name: "Sales",
              data: reports?.salesStats,
            },
            {
              name: "Revenue",
              data: reports?.revenueStats,
            },
            {
              name: "Customers",
              data: reports?.customerStats,
            },
          ],
          chart: {
            height: 350,
            type: "area",
            toolbar: {
              show: false,
            },
          },
          markers: {
            size: 4,
          },
          colors: ["#4154f1", "#2eca6a", "#ff771d"],
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.3,
              opacityTo: 0.4,
              stops: [0, 90, 100],
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          xaxis: {
            type: "category", // Change the x-axis type to "category"
            categories: dateStrings, // Use the dynamically generated date strings
            labels: {
              rotate: -45, // Rotate the labels for better visibility
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        }).render();

        var curves = echarts
          .init(document.querySelector("#trafficChart"))
          .setOption({
            tooltip: {
              trigger: "item",
            },
            legend: {
              top: "5%",
              left: "center",
            },
            series: [
              {
                name: "Access From",
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: "18",
                    fontWeight: "bold",
                  },
                },
                labelLine: {
                  show: false,
                },
                data: [
                  {
                    value: 1048,
                    name: "Search Engine",
                  },
                  {
                    value: 735,
                    name: "Direct",
                  },
                  {
                    value: 580,
                    name: "Email",
                  },
                  {
                    value: 484,
                    name: "Union Ads",
                  },
                  {
                    value: 300,
                    name: "Video Ads",
                  },
                ],
              },
            ],
          });

        let extra = document.querySelectorAll("#reportsChart > div");

        if (extra.length > 1) {
          console.log(extra[1]);
          extra[1].remove();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if(reports.customerStats.length)
      fetchData();
  }, [recentSales, reports, topSelling, topStats]);

  return (
    <>
      <div classname="home-div-container">
        <section className="rounded-3xl bg-white px-14 py-10">
          <>
            <main id="main" className="main">
              {/* <div className="pagetitle">
                <h1>Dashboard</h1>
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <div className="arrow">{">"}</div>
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </nav>
              </div> */}
              {/* End Page Title */}
              <section className="section dashboard">
                {/* <div className="row grid2"> */}
                <div className="row">
                  {/* Left side columns */}
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="overview">
                        {/* Sales Card */}
                        <div className="col-xxl-4 col-md-6">
                          <div className="card info-card sales-card">
                            <div className="filter">
                              <a
                                className="icon"
                                href="#"
                                data-bs-toggle="dropdown"
                              >
                                <i className="bi bi-three-dots" />
                              </a>
                              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                <li className="dropdown-header text-start">
                                  <h6>Filter</h6>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Today
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    This Month
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    This Year
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="card-body">
                              <h5 className="card-title">
                                Sales <span>| Month</span>
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    viewBox="0 0 24 24"
                                  >
                                    <g
                                      fill="none"
                                      stroke="#6f6ff5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                    >
                                      <path d="M4 19a2 2 0 1 0 4 0a2 2 0 1 0-4 0m11 0a2 2 0 1 0 4 0a2 2 0 1 0-4 0" />
                                      <path d="M17 17H6V3H4" />
                                      <path d="m6 5l14 1l-1 7H6" />
                                    </g>
                                  </svg>
                                </div>
                                <div className="ps-3">
                                  <h6>{topStats.currentMonthSalesCount}</h6>
                                  <span
                                    className={
                                      topStats.salesChangeDirection ===
                                      "decrease"
                                        ? "bg-danger small fw-bold pt-1"
                                        : "text-success small fw-bold pt-1"
                                    }
                                  >
                                    {topStats?.salesPercentageChange || "--"}%
                                  </span>{" "}
                                  <span className="text-muted small ps-1 pt-2">
                                    {topStats.salesChangeDirection}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Sales Card */}
                        {/* Revenue Card */}
                        <div className="col-xxl-4 col-md-6">
                          <div className="card info-card revenue-card">
                            <div className="filter">
                              <a
                                className="icon"
                                href="#"
                                data-bs-toggle="dropdown"
                              >
                                <i className="bi bi-three-dots" />
                              </a>
                              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                <li className="dropdown-header text-start">
                                  <h6>Filter</h6>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Today
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    This Month
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    This Year
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="card-body">
                              <h5 className="card-title">
                                Revenue <span>| This Month</span>
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    viewBox="0 0 24 24"
                                  >
                                    <g
                                      fill="none"
                                      stroke="#6f6ff5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                    >
                                      <path d="M9 5H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-2" />
                                      <path d="M9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v0a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2zm5 6h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3H10m2 0v1m0-8v1" />
                                    </g>
                                  </svg>
                                </div>
                                <div className="ps-3">
                                  <h6>GH₵ {topStats.currentMonthRevenue}</h6>
                                  <span
                                    className={
                                      topStats.revenueChangeDirection ===
                                      "decrease"
                                        ? "bg-danger small fw-bold pt-1"
                                        : "text-success small fw-bold pt-1"
                                    }
                                  >
                                    {topStats.revenuePercentageChange || "--"}%
                                  </span>{" "}
                                  <span className="text-muted small ps-1 pt-2">
                                    {topStats.revenueChangeDirection}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Revenue Card */}
                        {/* Customers Card */}
                        <div className="col-xxl-4 col-xl-12">
                          <div className="card info-card customers-card">
                            <div className="filter">
                              <a
                                className="icon"
                                href="#"
                                data-bs-toggle="dropdown"
                              >
                                <i className="bi bi-three-dots" />
                              </a>
                              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                <li className="dropdown-header text-start">
                                  <h6>Filter</h6>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Today
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    This Month
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    This Year
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="card-body">
                              <h5 className="card-title">
                                Customers <span>| This Month</span>
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="none"
                                      stroke="#6f6ff5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M5 7a4 4 0 1 0 8 0a4 4 0 1 0-8 0M3 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2m1-17.87a4 4 0 0 1 0 7.75M21 21v-2a4 4 0 0 0-3-3.85"
                                    />
                                  </svg>
                                </div>
                                <div className="ps-3">
                                  <h6>{topStats.currentMonthCustomerCount}</h6>
                                  <span
                                    className={
                                      topStats.customerChangeDirection ===
                                      "decrease"
                                        ? "bg-danger small fw-bold pt-1"
                                        : "text-success small fw-bold pt-1"
                                    }
                                  >
                                    {topStats.customerPercentageChange || "--"}%
                                  </span>{" "}
                                  <span className="text-muted small ps-1 pt-2">
                                    {topStats.customerChangeDirection}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Customers Card */}
                      </div>
                      {/* Reports */}
                      <div className="col-12">
                        <div className="card">
                          <div className="filter">
                            <a
                              className="icon"
                              href="#"
                              data-bs-toggle="dropdown"
                            >
                              <i className="bi bi-three-dots" />
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                              <li className="dropdown-header text-start">
                                <h6>Filter</h6>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Today
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  This Month
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  This Year
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="card-body">
                            <h5 className="card-title">
                              Reports <span>| Month</span>
                            </h5>
                            {/* Line Chart */}
                            <div id="reportsChart" />
                            {/* End Line Chart */}
                          </div>
                        </div>
                      </div>
                      {/* End Reports */}
                      {/* Recent Sales */}
                      <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                          <div className="filter">
                            <a
                              className="icon"
                              href="#"
                              data-bs-toggle="dropdown"
                            >
                              <i className="bi bi-three-dots" />
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                              <li className="dropdown-header text-start">
                                <h6>Filter</h6>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Today
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  This Month
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  This Year
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="card-body">
                            <h5 className="card-title">
                              Recent Sales <span>| Month</span>
                            </h5>
                            <table className="table-borderless datatable table">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Cashier</th>
                                  <th scope="col">Store</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Method</th>
                                </tr>
                              </thead>
                              <tbody>
                                {recentSales.map((sale) => (
                                  <tr>
                                    <th scope="row">
                                      <span>#{sale.saleId}</span>
                                    </th>
                                    <td>{sale.cashierName}</td>
                                    <td>
                                      {/* <a href="#" className="text-primary" */}
                                        <span>{sale.storeName}</span>
                                      {/* </a> */}
                                    </td>
                                    <td>GH₵ {sale.totalAmount}</td>
                                    <td>
                                      <span className="badge bg-success">
                                        {sale.paymentMethod}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* End Recent Sales */}
                      {/* Top Selling */}
                      <div className="col-12">
                        <div className="card top-selling overflow-auto">
                          <div className="filter">
                            <a
                              className="icon"
                              href="#"
                              data-bs-toggle="dropdown"
                            >
                              <i className="bi bi-three-dots" />
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                              <li className="dropdown-header text-start">
                                <h6>Filter</h6>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Today
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  This Month
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  This Year
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="card-body pb-0">
                            <h5 className="card-title">
                              Top Selling <span>| Month</span>
                            </h5>
                            <table className="table-borderless table">
                              <thead>
                                <tr>
                                  <th scope="col">Product</th>
                                  <th scope="col">Price</th>
                                  <th scope="col">Sold</th>
                                  <th scope="col">Revenue</th>
                                  <th scope="col">Quantity Left</th>
                                </tr>
                              </thead>
                              <tbody>
                                {topSelling.map(product => (
                                  <tr>
                                  <th scope="row">
                                    <a
                                        href="#"
                                        className="text-primary fw-bold"
                                        disabled
                                      >
                                        <span>{product.productName}</span>
                                    </a>
                                  </th>
                                  <td>
                                    GH₵ {product.sellingPrice}
                                  </td>
                                  <td className="fw-bold">{product.quantitySold}</td>
                                  <td>GH₵ {product.totalAmount}</td>
                                  <td><span className={product.stockLeft <= 10 ? "badge bg-warning": "badge bg-success"}>
                                      {product.stockLeft}
                                    </span></td>
                                </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* End Top Selling */}
                    </div>
                  </div>
                  {/* End Left side columns */}
                  {/* Right side columns */}
                  <div className="col-lg-4" style={{ display: "none" }}>
                    {/* Budget Report */}
                    <div className="card" style={{ display: "none" }}>
                      <div className="filter">
                        <a className="icon" href="#" data-bs-toggle="dropdown">
                          <i className="bi bi-three-dots" />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                          <li className="dropdown-header text-start">
                            <h6>Filter</h6>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Today
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              This Month
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              This Year
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="card-body pb-0">
                        <h5 className="card-title">
                          Budget Report <span>| This Month</span>
                        </h5>
                        <div
                          id="budgetChart"
                          style={{ minHeight: 400 }}
                          className="echart"
                        />
                      </div>
                    </div>
                    {/* End Budget Report */}
                    {/* Website Traffic */}
                    <div className="card" style={{ display: "none" }}>
                      <div className="filter">
                        <a className="icon" href="#" data-bs-toggle="dropdown">
                          <i className="bi bi-three-dots" />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                          <li className="dropdown-header text-start">
                            <h6>Filter</h6>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Today
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              This Month
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              This Year
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="card-body pb-0">
                        <h5 className="card-title">
                          Website Traffic <span>| Today</span>
                        </h5>
                        <div
                          id="trafficChart"
                          style={{ minHeight: 400 }}
                          className="echart"
                        />
                      </div>
                    </div>
                    {/* End Website Traffic */}
                    {/* News & Updates Traffic */}
                    <div className="card" style={{ display: "none" }}>
                      <div className="filter">
                        <a className="icon" href="#" data-bs-toggle="dropdown">
                          <i className="bi bi-three-dots" />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                          <li className="dropdown-header text-start">
                            <h6>Filter</h6>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              Today
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              This Month
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              This Year
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="card-body pb-0">
                        <h5 className="card-title">
                          News &amp; Updates <span>| Today</span>
                        </h5>
                        <div className="news">
                          <div className="post-item clearfix">
                            <img src="assets/img/news-1.jpg" alt="" />
                            <h4>
                              <a href="#">Nihil blanditiis at in nihil autem</a>
                            </h4>
                            <p>
                              Sit recusandae non aspernatur laboriosam. Quia
                              enim eligendi sed ut harum...
                            </p>
                          </div>
                          <div className="post-item clearfix">
                            <img src="assets/img/news-2.jpg" alt="" />
                            <h4>
                              <a href="#">Quidem autem et impedit</a>
                            </h4>
                            <p>
                              Illo nemo neque maiores vitae officiis cum eum
                              turos elan dries werona nande...
                            </p>
                          </div>
                          <div className="post-item clearfix">
                            <img src="assets/img/news-3.jpg" alt="" />
                            <h4>
                              <a href="#">
                                Id quia et et ut maxime similique occaecati ut
                              </a>
                            </h4>
                            <p>
                              Fugiat voluptas vero eaque accusantium eos.
                              Consequuntur sed ipsam et totam...
                            </p>
                          </div>
                          <div className="post-item clearfix">
                            <img src="assets/img/news-4.jpg" alt="" />
                            <h4>
                              <a href="#">Laborum corporis quo dara net para</a>
                            </h4>
                            <p>
                              Qui enim quia optio. Eligendi aut asperiores enim
                              repellendusvel rerum cuder...
                            </p>
                          </div>
                          <div className="post-item clearfix">
                            <img src="assets/img/news-5.jpg" alt="" />
                            <h4>
                              <a href="#">
                                Et dolores corrupti quae illo quod dolor
                              </a>
                            </h4>
                            <p>
                              Odit ut eveniet modi reiciendis. Atque cupiditate
                              libero beatae dignissimos eius...
                            </p>
                          </div>
                        </div>
                      {/* End sidebar recent posts*/}
                      </div>
                    </div>
                    {/* End News & Updates */}
                  </div>
                  {/* End Right side columns */}
                </div>
              </section>
            </main>
            {/* End #main */}
          </>
        </section>
      </div>
    </>
  );
};

export default Content;
