import { useState } from "react";
import { TiPlus } from "react-icons/ti";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";

import "./Content.css";
import "../../Shared/css/MainContentContainer.css";

import ItemDetails from "./ItemDetails";
import AddComponent from "./AddComponent";
import DoneAlert from "../../Alerts/DoneAlert";
import { capitalize } from "lodash";

// HANDLE ITEM IMAGE PATH ISSUE

const Content = (props) => {
  const { items, categories } = useSelector((state) => state.auth);
  const [alert, setAlert] = useState([false]);
  const [categoryForm, setCategoryForm] = useState(false);
  const [productForm, setProductForm] = useState(false);
  const [activeCategory, setActiveCategory] = useState({name: "All"})
  const [activeItem, setActiveItem] = useState(null);
  
   // FUNCTION TO SEARCH
   let result = 0;
   if (props.search.length > 0) {
     let searchWord = props.search.toLowerCase();
 
     result = items.filter((item) =>
       item.name.toLowerCase().match(searchWord) ||
       item.description.toLowerCase().match(searchWord) ||
       item.stock_left.toString().match(searchWord) ||
       item.selling_price.toString().match(searchWord)
     );
   }

  return (
    <>
      <main className="manager-items-main-section main-content-container manager-items-container flex flex-col gap-y-8">
        <section className="top">
          <div className="heading items-centermb-4 mb-4 flex justify-between">
            <div className="left flex items-center gap-5">
              <h2 className="text-3xl font-semibold">Categories</h2>
              <p className="rounded bg-blue  px-3 py-2 font-semibold text-white">
                {items.length} total items
              </p>
            </div>

            <div className="right flex items-center gap-x-4">
              <button
                className="flex cursor-pointer items-center gap-x-1 rounded-lg bg-blue p-3 text-white hover:bg-lighterpurple"
                type="button"
                onClick={() => setCategoryForm(true)}
              >
                <TiPlus /> Add Category
              </button>
              <button
                className="flex cursor-pointer items-center gap-x-1 rounded-lg bg-blue p-3 text-white hover:bg-lighterpurple"
                type="button"
                onClick={() => setProductForm(true)}
                disabled={categories.length <= 1}
              >
                <TiPlus /> Add Product
              </button>
            </div>
          </div>

          <div className="categories flex flex-nowrap gap-x-3 overflow-x-scroll rounded-3xl bg-accentpurple px-8 py-2">
            {categories.map((category, index) => (
              <p
                className={`${
                  category.name === activeCategory?.name ? "active" : null
                } cursor-pointer p-2 text-xl font-semibold`}
                onClick={() => {
                  setActiveCategory(category)
                }}
                id={index}
              >
                {category.name}
              </p>
            ))}
          </div>
        </section>

        <section className="bottom flex h-full w-full justify-between overflow-scroll lg:gap-x-4 2xl:gap-x-12">
          <section className="left flex h-full w-full flex-col gap-y-2 overflow-scroll">
            <div className="heading mx-8 flex text-2xl font-semibold">
              <div className="w-3/5">
                <p>Product</p>
              </div>
              <div className="flex w-2/5 justify-between">
                <p>Price</p>
                <p>In Stock</p>
              </div>
            </div>

            <section className="overflow-y-scroll pr-2">
              { result.length > 0 ?
                  result.map((item, index) => (
                    <div
                    id={index}
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-accentpurple bg-accentpurple px-8 py-2 hover:border-blue"
                    onClick={() => setActiveItem(item)}
                    >
                      <div className="product w-3/5 pr-4">
                        <h2 className="truncate font-semibold">
                          {item.name}
                        </h2>
                        <p className="truncate text-fadegrey">
                          {item.description ? item.description : "-"}
                        </p>
                      </div>

                      <div className="info flex w-2/5 justify-between font-semibold">
                        <p>GH¢ {item?.selling_price}</p>
                        <p>{item?.stock_left}</p>
                      </div>
                    </div> 
                  ))
                :
               props.loading ?
                  <>
                  <div
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-accentpurple bg-accentpurple px-8 py-2 hover:border-blue"
                  >
                    <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                      {/* Adjust border radius */}
                    </ContentLoader>
                  </div> 
                  <div
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-accentpurple bg-accentpurple px-8 py-2 hover:border-blue"
                  >
                    <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                      {/* Adjust border radius */}
                    </ContentLoader>
                  </div> 
                  <div
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-accentpurple bg-accentpurple px-8 py-2 hover:border-blue"
                  >
                    <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                      {/* Adjust border radius */}
                    </ContentLoader>
                  </div> 
                  <div
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-accentpurple bg-accentpurple px-8 py-2 hover:border-blue"
                  >
                    <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                      {/* Adjust border radius */}
                    </ContentLoader>
                  </div> 
                  <div
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-accentpurple bg-accentpurple px-8 py-2 hover:border-blue"
                  >
                    <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                      {/* Adjust border radius */}
                    </ContentLoader>
                  </div> 
                  <div
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-accentpurple bg-accentpurple px-8 py-2 hover:border-blue"
                  >
                    <ContentLoader
                      speed={1}
                      interval={0.15}
                      width={1400} // Set the width to 80px
                      height={40} // Set the height to 40px
                      viewBox="0 0 1400 40" // Adjust the viewBox accordingly
                      backgroundColor="var(--accent-purple)" // Set the background color to purple
                      foregroundColor="var(--background-color)"
                    >
                      <rect x="0" y="0" rx="8" ry="8" width="1400" height="40" />{" "}
                      {/* Adjust border radius */}
                    </ContentLoader>
                  </div> 
                  </>
                :
              activeCategory?.name === "All" ? 
                items.map((item, index) => (
                  <div
                    id={index}
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-accentpurple bg-accentpurple px-8 py-2 hover:border-blue"
                    onClick={() => setActiveItem(item)}
                    >
                      <div className="product w-3/5 pr-4">
                        <h2 className="truncate font-semibold">
                          {item.name}
                        </h2>
                        <p className="truncate text-fadegrey">
                          {item.description ? item.description : "-"}
                        </p>
                      </div>

                      <div className="info flex w-2/5 justify-between font-semibold">
                        <p>GH¢ {item?.selling_price}</p>
                        <p>{item?.stock_left}</p>
                      </div>
                    </div> 
                )) 
                :
                items.filter(item => item?.category_id === activeCategory?.category_id).map((item, index) => (
                  <div
                    id={index}
                    className="products hover:border-1 mb-4 flex cursor-pointer items-center justify-between rounded-2xl border-2 border-accentpurple bg-accentpurple px-8 py-2 hover:border-blue"
                    onClick={() => setActiveItem(item)}
                    >
                      <div className="product w-3/5 pr-4">
                        <h2 className="truncate font-semibold">
                          {item.name}
                        </h2>
                        <p className="truncate text-fadegrey">
                          {item.description ? item.description : "-"}
                        </p>
                      </div>

                      <div className="info flex w-2/5 justify-between font-semibold">
                        <p>GH¢ {item?.selling_price}</p>
                        <p>{item?.stock_left}</p>
                      </div>
                    </div> 
                )) 
              }
            </section>
          </section>
          <section className="right flex flex-col gap-y-2 overflow-scroll lg:w-3/5 2xl:w-2/5">
            <p className="text-center text-2xl font-semibold">Details</p>
            <div className="overflow-y-scroll">
            {activeItem ? (
                <ItemDetails
                  item={activeItem}
                  alertState={setAlert}
                  deactivate={setActiveItem}
                />
              ) : (
                <div className="item-not-selected text-center">
                  <p className="text-fadegrey">
                    Select product to view details
                  </p>
                </div>
              )}
            </div>
          </section>
        </section>
      </main>

      {categoryForm ? (
        <AddComponent
          formState={setCategoryForm}
          add="category"
        />
      ) : null}
      {productForm ? (
        <AddComponent
          formState={setProductForm}
          add="product"
          alertState={setAlert}
        />
      ) : null}

      {alert[0] ? (
        <DoneAlert alertMessage={alert[1]} alertState={setAlert} />
      ) : null}
    </>
  );
};

export default Content;
