import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const ManagerRoute = () => {
  const { userProfile } = useSelector((state) => state.auth);

  return userProfile && userProfile.role === "manager" ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ManagerRoute;
